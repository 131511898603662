import { Dispatch } from "react";
import { toast } from "react-hot-toast";
import S3 from "./S3";
import { ChonkyFileActionData, FileArray, FileData } from "./chonky";
import {
  compressAndDownload,
  getCurrentFiles,
  compressMultiAndDownload,
} from "./helpers";
import { BASE_URL } from "../../../api/api";

export const formatPath = (prefix): string => (prefix === "/" ? "" : prefix);
const handleFileAction = async (
  s3: S3,
  data: ChonkyFileActionData,
  currentFolderId: string,
  setCurrentFolderId: Dispatch<string>,
  setFiles: Dispatch<FileArray>,
  setDeleteModal: Dispatch<boolean>,
  setIdsToDelete: Dispatch<string[]>,
  setFileToUpdate: Dispatch<FileData>,
  setCopyModal: Dispatch<boolean>,
  setRenameModal: Dispatch<boolean>,
  setRenameModalValue: Dispatch<string>,
  setCreateModal: Dispatch<boolean>,
  setUploadModal: Dispatch<boolean>,
  setSelectedPath: Dispatch<string>,
  nav: any,
  restrictedExtensions: string[],
  setCompressing: Dispatch<boolean>
  // eslint-disable-next-line consistent-return
): Promise<void> => {
  const { payload, id: actionId }: any = data;
  if (actionId === "change_selection" && payload && setSelectedPath) {
    setSelectedPath([...payload.selection].join(", "));
  } else if (
    actionId === "open_files" &&
    payload.files &&
    payload.files.length === 1 &&
    payload.targetFile &&
    payload.targetFile.isDir
  ) {
    const { id: fileId } = payload.targetFile;
    setCurrentFolderId(formatPath(fileId));
  } else if (actionId === "move_files") {
    const sourcePath = formatPath(payload.source.id);
    const destinationPath = formatPath(payload.destination.id);
    const filesToMove = payload.files.map((f) => f.name);

    await Promise.all(
      filesToMove.map(async (fileToMove) => {
        const sourceKey = `${sourcePath}${fileToMove}`;
        const destinationKey = `${destinationPath}${fileToMove}`;
        await s3.recursiveCopy(sourceKey, destinationKey);
        await s3.recursiveDelete([sourceKey]);
        getCurrentFiles(s3, currentFolderId).then(setFiles);
      })
    );
  } else if (actionId === "delete_files") {
    const idsToDelete = data.state.selectedFilesForAction.map((f) => f.id);
    setIdsToDelete(idsToDelete);
    setDeleteModal(true);
  } else if (actionId === "copy_files") {
    if (data.state.selectedFilesForAction.length !== 1) {
      toast.error("Cannot copy than one folder at a time");
    } else if (
      restrictedExtensions &&
      restrictedExtensions.length !== 0 &&
      restrictedExtensions.some((p) =>
        data.state.selectedFilesForAction[0].name.endsWith(p)
      )
    ) {
      toast.error("Cannot copy this file");
    } else {
      const fileToCopy = data.state.selectedFilesForAction[0];
      setFileToUpdate(fileToCopy);
      setCopyModal(true);
    }
  } else if (actionId === "rename_file") {
    if (data.state.selectedFilesForAction.length !== 1) {
      toast.error("Cannot rename more than one file/folder at a time");
    } else if (
      restrictedExtensions &&
      restrictedExtensions.length !== 0 &&
      restrictedExtensions.some((p) =>
        data.state.selectedFilesForAction[0].name.endsWith(p)
      )
    ) {
      toast.error("Cannot rename this file");
    } else {
      const fileToRename = data.state.selectedFilesForAction[0];
      setRenameModalValue(fileToRename.name);
      setFileToUpdate(fileToRename);
      setRenameModal(true);
    }
  } else if (actionId === "create_folder") {
    setCreateModal(true);
  } else if (actionId === "upload_files") {
    setUploadModal(true);
  } else if (
    actionId === "download_files" ||
    (actionId === "mouse_click_file" &&
      payload.clickType === "double" &&
      !payload.file.isDir)
  ) {
    if (
      data.state.selectedFilesForAction.length === 1 &&
      data.state.selectedFilesForAction[0].isDir
    ) {
      setCompressing(true);
      await compressAndDownload(data.state.selectedFilesForAction[0].id, s3);
      return setCompressing(false);
    }
    if (data.state.selectedFilesForAction.length > 1) {
      setCompressing(true);
      const ids = data.state.selectedFilesForAction.map((f) => f.id);
      await compressMultiAndDownload(ids, s3);
      return setCompressing(false);
    }
    if (
      restrictedExtensions &&
      restrictedExtensions.length !== 0 &&
      restrictedExtensions.some((p) =>
        data.state.selectedFilesForAction[0].name.endsWith(p)
      )
    ) {
      toast.error("Cannot Download");
    } else {
      const token = localStorage.getItem("accessToken");

      const { id } = data.state.selectedFilesForAction[0];
      const res = await fetch(
        `${BASE_URL}/actions/exchange-token?type=presigned_s3_url&object=${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const json = await res.json();
      // @ts-ignore
      window.location = json.presigned_s3_url;
    }
  } else if (actionId === "create_task") {
    const { selectedFilesForAction } = data.state;
    const qs = selectedFilesForAction.map((f) => `file=${f.id}`).join("&");
    nav(`../tasks/create?${qs}`);
  }
};
export default handleFileAction;
