import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  Card,
  TextField,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { ChangeEvent, useEffect, useState } from "react";
import toast from "react-hot-toast";
import NotFound from "../NotFound";
import {
  useDeleteAuthorizationMutation,
  useGetAuthorizationQuery,
  useUpdateAuthorizationMutation,
} from "../../api/api";
import ScopeCard from "../../components/dashboard/authorization/ScopeCard";
import { formatDate } from "../../utils/formatDate";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import { zeros } from "../../api/transforms";
import type { Scopes } from "../../types/authorization";
import ProgressScreen from "../../components/ProgressScreen";

const AuthorizationDetails = (): JSX.Element => {
  // hooks
  const { authorizationId } = useParams();
  const {
    data: authorization,
    isError,
    error: getError,
    isLoading,
  } = useGetAuthorizationQuery(authorizationId);

  const navigate = useNavigate();
  const [deleteAuthorization, { isLoading: isDeleting, error: deleteError }] =
    useDeleteAuthorizationMutation();

  const [updateAuthorization, { error: updateError, isLoading: isUpdating }] =
    useUpdateAuthorizationMutation();

  const [scopes, setScopes] = useState<Scopes>(zeros);
  const [description, setDescription] = useState<string>("");
  useEffect(() => {
    if (authorization && authorization.scopes) {
      setDescription(authorization.description);
      setScopes(authorization.scopes);
    }
  }, [authorization]);

  // handlers
  const handleChangeDescription = (e: ChangeEvent<HTMLInputElement>): void => {
    setDescription(e.target.value);
  };

  const handleDelete = (): void => {
    deleteAuthorization(authorizationId);
    navigate("/dashboard/authorizations");
  };

  const handleSave = (): void => {
    if (updateError) {
      toast.error("Something went wrong!");
    }
    updateAuthorization({
      id: authorization.id,
      description,
      scopes,
    });
    toast.success("Authorization updated!");
  };

  // returns
  if (isError || getError) {
    return <NotFound />;
  }
  if (isLoading || isDeleting || isUpdating) {
    return <ProgressScreen />;
  }
  if (updateError || deleteError) {
    return <pre>{JSON.stringify({ updateError, deleteError })}</pre>;
  }

  return (
    <>
      <Helmet>
        <title> Authorization Details | NeuroScale</title>
      </Helmet>
      <Box
        data-test="authorization-overview"
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Authorization Details
              </Typography>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <Button
                  data-test="authorization-save-button"
                  sx={{ m: 1 }}
                  variant="contained"
                  onClick={handleSave}
                >
                  Save
                </Button>
                <DeleteConfirmation
                  data-test="delete-single-authorization-button"
                  selectedResources={[authorizationId]}
                  title="Delete this authorization?"
                  deleteResource={handleDelete}
                  variant="contained"
                />
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Grid container>
              <Box sx={{ width: "100%" }} mb={3}>
                <Card sx={{ p: 3, display: "flex", flexWrap: "wrap" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 3,
                      mr: 3,
                    }}
                  >
                    <b> ID: </b>
                    &emsp;
                    <code
                      style={{
                        padding: "6px 3px 1px 3px ",
                        borderRadius: 5,
                        background: "rgba(145, 158, 171, 0.24)",
                      }}
                    >
                      {authorization.id}
                    </code>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 3,
                      mr: 3,
                    }}
                  >
                    <b> Access Token: </b>
                    &emsp;
                    <code
                      style={{
                        padding: "6px 3px 1px 3px ",
                        borderRadius: 5,
                        background: "rgba(145, 158, 171, 0.24)",
                      }}
                    >
                      {authorization.access_token}
                    </code>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 3,
                      mr: 3,
                    }}
                  >
                    <b> Updated At: </b>
                    &emsp;
                    <code
                      style={{
                        padding: "6px 3px 1px 3px ",
                        borderRadius: 5,
                        background: "rgba(145, 158, 171, 0.24)",
                      }}
                    >
                      {formatDate(authorization.updated_at)}
                    </code>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 3,
                      mr: 3,
                    }}
                  >
                    <b> Created At: </b>
                    &emsp;
                    <code
                      style={{
                        padding: "6px 3px 1px 3px ",
                        borderRadius: 5,
                        background: "rgba(145, 158, 171, 0.24)",
                      }}
                    >
                      {formatDate(authorization.created_at)}
                    </code>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 3,
                      mr: 3,
                    }}
                  >
                    <b> Description: </b>
                    &emsp;
                    <TextField
                      data-test="authorization-description-input"
                      multiline
                      sx={{ maxWidth: 350 }}
                      name="description"
                      onChange={handleChangeDescription}
                      value={description}
                    />
                  </Box>
                </Card>
              </Box>
              <Box sx={{ width: "100%" }}>
                <Box mt={3} mb={2}>
                  <Typography variant="h6">Scopes</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-around",
                  }}
                >
                  {["pipeline", "instance", "task", "user"].map((resource) => (
                    <ScopeCard
                      key={resource}
                      resource={resource}
                      scopes={scopes}
                      setScopes={setScopes}
                    />
                  ))}
                </Box>
              </Box>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default AuthorizationDetails;
