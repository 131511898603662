import { createSvgIcon } from "@mui/material";

const Pipe = createSvgIcon(
  <svg
    viewBox="0 0 550 550"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M512 180V30c0-16.553-13.462-30-30-30s-30 13.447-30 30v150c0 16.553 13.462 30 30 30s30-13.447 30-30zM60 482V332c0-16.553-13.462-30-30-30S0 315.447 0 332v150c0 16.553 13.462 30 30 30s30-13.447 30-30zm362-302h-75c-8.401 0-15 7.599-15 16v120c0 15.601-2.401 30.601-6.301 44.7 0 .15-.075.375-.149.601-.075.225-.149.45-.149.6C306.937 425.479 246.837 482 167 482H90V332h77c8.401 0 15-7.599 15-16V196c0-15.601 2.401-30.601 6.301-44.7 0-.15.074-.375.149-.601l.001-.002c.074-.225.148-.448.148-.598C208.447 81.756 272.27 30 347 30h75v150z"
    />
  </svg>,
  "Pipe"
);

export default Pipe;
