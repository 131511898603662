import type { ChangeEvent, Dispatch } from "react";
import {
  FormControlLabel,
  NativeSelect,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import { Parameter } from "../../../types/pipeline";
import MultiSelectType from "./MultiSelectType";
import ListTypeNative from "./ListTypeNative";
import DictTypeNative from "./DictTypeNative";

const InputByDataType = (p: {
  param: Parameter;
  setCategories: Dispatch<any>;
  setInvalidCategory: Dispatch<string>;
  invalidCategory: string;
}): JSX.Element => {
  const [defaultError, setDefaultError] = useState(false);

  const handleBool = (e): void => {
    const { name, value } = e.target;
    p.setCategories((draft) => {
      draft
        .find((c) => c.name === p.param.category)
        .params.find((param) => param.name === name).value = JSON.parse(value);
    });
  };

  const handleNumber = (e): void => {
    const { name, value } = e.target;
    let finalValue = value;
    if (value.endsWith(".")) {
      finalValue = `${value}0`;
    }
    if (value === "") {
      p.setCategories((draft) => {
        draft
          .find((c) => c.name === p.param.category)
          .params.find((param) => param.name === name).value = finalValue;
      });
    }
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(parseFloat(finalValue))) {
      p.setInvalidCategory(p.param.category);
      setDefaultError(true);
    } else {
      p.setCategories((draft) => {
        draft
          .find((c) => c.name === p.param.category)
          .params.find((param) => param.name === name).value =
          parseFloat(finalValue);
      });
      p.setInvalidCategory("");
      setDefaultError(false);
    }
  };
  const handleChange = (e: ChangeEvent<any>): void => {
    const { name, value } = e.target;
    p.setCategories((draft) => {
      draft
        .find((c) => c.name === p.param.category)
        .params.find((param) => param.name === name).value = value;
    });
  };

  if (p.param.editable === false) {
    return (
      <Tooltip title="Read Only">
        <p>{JSON.stringify(p.param.value)}</p>
      </Tooltip>
    );
  }

  if (p.param.type === "float" || p.param.type === "int") {
    return (
      <TextField
        error={defaultError}
        helperText={
          defaultError &&
          `Invalid entry. Must be a valid parameter of type: ${p.param.type}. See valid syntax at https://docs.neuroscale.io/docs/param-syntax `
        }
        name={p.param.name}
        value={p.param.value}
        onChange={handleNumber}
        required={Boolean(p.param.value)}
        type="number"
        inputProps={{
          style: { fontFamily: "mono" },
        }}
      />
    );
  }

  if (p.param.type === "bool") {
    return (
      <RadioGroup
        row
        name={p.param.name}
        value={p.param.value}
        onChange={handleBool}
      >
        <FormControlLabel value control={<Radio />} label="True" />
        <FormControlLabel value={false} control={<Radio />} label="False" />
      </RadioGroup>
    );
  }
  if (p.param.type.startsWith("dict")) {
    return (
      <DictTypeNative
        type={p.param.type}
        dict={p.param.value}
        name={p.param.name}
        handleChange={handleChange}
      />
    );
  }
  if (p.param.type.startsWith("list")) {
    return (
      <ListTypeNative
        handleChange={handleChange}
        name={p.param.name}
        list={p.param.value as any[]}
        type={p.param.type}
      />
    );
  }
  if (p.param.type === "object") {
    if (!p.param.domain && !p.param.domain.kind) {
      return <p>cannot render</p>;
    }
    if (
      p.param.domain.kind === "choices-multi" ||
      p.param.domain.kind === "choices-combo"
    ) {
      return (
        <MultiSelectType
          name={p.param.name}
          handleChange={handleChange}
          domain={p.param.domain}
        />
      );
    }
    if (p.param.domain.kind === "choices-single") {
      return (
        <NativeSelect
          defaultValue={p.param.value}
          value={p.param.value}
          name={p.param.name}
          onChange={handleChange}
        >
          {p.param.domain.values.map((v) => {
            const embeddedStringsVal = v;
            return (
              <option key={embeddedStringsVal} value={embeddedStringsVal}>
                {embeddedStringsVal}
              </option>
            );
          })}
        </NativeSelect>
      );
    }
    throw new Error("Bad Domain Schema!");
  }
  return (
    <TextField
      fullWidth
      sx={{ mr: 2 }}
      name={p.param.name}
      value={p.param.value}
      onChange={handleChange}
      required={Boolean(p.param.value)}
      inputProps={{ style: { fontFamily: "mono" } }}
    />
  );
};
// const InputByType = ({
//   param,
//   setCategories,
//   setInvalidCategory,
//   invalidCategory,
// }: {
//   param: Parameter;
//   setCategories: Dispatch<any>;
//   setInvalidCategory: Dispatch<string>;
//   invalidCategory: string;
// }): JSX.Element => {
//   // console.debug({ name: param.name, type: param.type, value: param.value });
//   const [defaultError, setDefaultError] = useState<boolean>(false);
//   const handleChange = (e: ChangeEvent<any>, type: string): void => {
//     const { name, value } = e.target;
//     if (type === "int" || type === "float") {
//       try {
//         JSON.parse(value.replaceAll("'", '"'));
//         setInvalidCategory("");
//         setDefaultError(false);
//       } catch (err) {
//         setInvalidCategory(param.category);
//         setDefaultError(true);
//       }
//     }
//     setCategories((draft) => {
//       draft
//         .find((c) => c.name === param.category)
//         .params.find((p) => p.name === name).value = value;
//     });
//   };
//   useEffect(() => {
//     if (!invalidCategory) {
//       setDefaultError(false);
//     }
//   }, [invalidCategory]);
//   const { type, editable } = param;
//   if (editable === false) {
//     return (
//       <Tooltip title="Read Only">
//         <p>{param.value}</p>
//       </Tooltip>
//     );
//   }
//   if (type === "float" || type === "int") {
//     return (
//       <TextField
//         error={defaultError}
//         helperText={
//           defaultError &&
//           `Invalid entry. Must be a valid parameter of type: ${param.type}. See valid syntax at https://docs.neuroscale.io/docs/param-syntax `
//         }
//         name={param.name}
//         type="number"
//         value={param.value}
//         onChange={(e): void => handleChange(e, param.type)}
//         required={Boolean(param.value)}
//         inputProps={{
//           style: { fontFamily: "mono" },
//         }}
//       />
//     );
//   }

//   if (type.includes("dict")) {
//     return (
//       <DictType
//         type={type as "dict" | "dict[str,float]"}
//         dictAsString={String(param.value)}
//         paramName={param.name}
//         handleChange={handleChange}
//         category={param.category}
//         setInvalidCategory={setInvalidCategory}
//       />
//     );
//   }
//   if (type.includes("list")) {
//     return (
//       <ListType
//         listAsString={String(param.value)}
//         name={param.name}
//         handleChange={handleChange}
//         type={type}
//         category={param.category}
//         setInvalidCategory={setInvalidCategory}
//       />
//     );
//   }
//   if (type === "object") {
//     if (!param.domain && !param.domain.kind) {
//       return <p>cannot render</p>;
//     }
//     if (
//       param.domain.kind === "choices-multi" ||
//       param.domain.kind === "choices-combo"
//     ) {
//       return (
//         <MultiSelectType
//           name={param.name}
//           handleChange={handleChange}
//           domain={param.domain}
//         />
//       );
//     }
//     if (param.domain.kind === "choices-single") {
//       return (
//         <NativeSelect
//           defaultValue={param.value}
//           value={param.value}
//           name={param.name}
//           onChange={(e): void => handleChange(e, param.type)}
//         >
//           {param.domain.values.map((v) => {
//             const embeddedStringsVal = `'${v}'`;
//             return (
//               <option key={embeddedStringsVal} value={embeddedStringsVal}>
//                 {embeddedStringsVal}
//               </option>
//             );
//           })}
//         </NativeSelect>
//       );
//     }
//     throw new Error("Bad Domain Schema!");
//   }
//   return (
//     <TextField
//       error={defaultError}
//       helperText={defaultError && `Invalid ${param.type} parameter`}
//       fullWidth
//       sx={{ mr: 2 }}
//       name={param.name}
//       value={param.value}
//       onChange={(e): void => handleChange(e, param.type)}
//       required={Boolean(param.value)}
//       inputProps={{ style: { fontFamily: "mono" } }}
//     />
//   );
// };
export default InputByDataType;
