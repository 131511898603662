import { createContext, useEffect, useReducer } from "react";
import type { ReactNode } from "react";
import type User from "../types/user";
import { authApi, useGetUserQuery } from "../api/api";

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

export interface AuthContextValue extends State {
  register: (args: User) => Promise<void>;
  restrictedExtensions: string[];
  platform: "JWT";
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
  forgotPassword: (email: string) => Promise<void>;
  resetPassword: (
    uid: string,
    token: string,
    newPassword1: string,
    newPassword2: string
  ) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: "INITIALIZE";
  payload: {
    isAuthenticated: boolean;
  };
};

type LoginAction = {
  type: "LOGIN";
};

type LogoutAction = {
  type: "LOGOUT";
};

type RegisterAction = {
  type: "REGISTER";
  payload: {
    user: User;
  };
};
type ForgotPassword = {
  type: "FORGOT_PASSWORD";
};
type ResetPassword = {
  type: "RESET_PASSWORD";
};

type Action =
  | InitializeAction
  | LoginAction
  | RegisterAction
  | LogoutAction
  | ForgotPassword
  | ResetPassword;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
    };
  },
  LOGIN: (state: State): State => ({
    ...state,
    isAuthenticated: true,
  }),
  FORGOT_PASSWORD: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state: State, action: RegisterAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: "JWT",
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
  logout: () => {},
  forgotPassword: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  restrictedExtensions: [],
});

export const AuthProvider = (props: AuthProviderProps): JSX.Element => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { data: user } = useGetUserQuery();
  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken) {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
          },
        });
      }
    };

    initialize().then();
  }, []);

  const login = async (username: string, password: string): Promise<void> => {
    const accessToken = await authApi.login({ username, password });

    localStorage.setItem("accessToken", accessToken);

    dispatch({
      type: "LOGIN",
    });
  };
  const register = async (registerArgs: User): Promise<void> => {
    await authApi.register(registerArgs);
  };

  const logout = (): void => {
    localStorage.removeItem("accessToken");
    dispatch({ type: "LOGOUT" });
  };

  const forgotPassword = async (email: string): Promise<void> => {
    await authApi.forgotPassword(email);
    dispatch({ type: "FORGOT_PASSWORD" });
  };
  const resetPassword = async (
    newPassword1: string,
    newPassword2: string,
    uid: string,
    token: string
  ): Promise<void> => {
    await authApi.resetPassword(newPassword1, newPassword2, uid, token);
    dispatch({ type: "FORGOT_PASSWORD" });
  };
  let restrictedExtensions: string[];
  if (user && user.storage_profile) {
    restrictedExtensions = user.storage_profile.restricted_extensions;
  }
  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: "JWT",
        login,
        logout,
        forgotPassword,
        resetPassword,
        restrictedExtensions,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
