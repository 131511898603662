import type { ChangeEvent, MouseEvent } from "react";
import {
  Box,
  Button,
  Checkbox,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { nanoid } from "@reduxjs/toolkit";
import { useState, useEffect } from "react";
import { snakeToSentence, truncate } from "../../../utils/stringTransform";
import { formatDate } from "../../../utils/formatDate";
import { Resource } from "../../../types/resources";
import { Task } from "../../../types/tasks";

interface ResourceTableProps {
  type?: string;
  resources: Resource[];
  headers: string[];
  name: string;
  selectedResources: string[];
  selectedAllResources: boolean;
  selectedSomeResources: boolean;
  handleSelectAllResources: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSelectOneResource: (
    e: ChangeEvent<HTMLInputElement>,
    id: string
  ) => void;
  bulkEdit: boolean;
  handlePageChange?: (
    e: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  page?: number;
  totalCount?: number;
}

const CellLink = (props: {
  firstColValue: string;
  name: string;
  resourceId: string;
}): JSX.Element => {
  const { firstColValue, name, resourceId } = props;
  return (
    <TableCell>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
        }}
      >
        <Box sx={{ ml: 1 }}>
          <Link
            color="inherit"
            component={RouterLink}
            to={`/dashboard/${name}/${resourceId}`}
            variant="subtitle2"
          >
            {firstColValue}
          </Link>
        </Box>
      </Box>
    </TableCell>
  );
};

export const SampleReportButton = ({
  pipelineId,
  variant,
}: {
  pipelineId: string;
  variant: "text" | "outlined" | "contained";
}): JSX.Element => {
  const [isValidLink, setIsValidLink] = useState(false);

  useEffect((): void => {
    try {
      fetch(
        `https://neuroscale-insights.s3.amazonaws.com/sample-reports/${pipelineId}.html`,
        { method: "HEAD" }
      ).then((res) => {
        if (res.status < 300) {
          setIsValidLink(true);
        }
      });
      return null;
    } catch {
      return null;
    }
  }, []);
  return (
    isValidLink && (
      <Button
        variant={variant}
        target="_blank"
        href={`https://neuroscale-insights.s3.amazonaws.com/sample-reports/${pipelineId}.html`}
      >
        Sample Report
      </Button>
    )
  );
};

const ResourceTable = (props: ResourceTableProps): JSX.Element => {
  const {
    type,
    resources,
    headers,
    name,
    page,
    selectedResources,
    handleSelectAllResources,
    handleSelectOneResource,
    handlePageChange,
    selectedAllResources,
    selectedSomeResources,
    bulkEdit,
    totalCount,
  } = props;
  const toTask = (r: Resource): Task => r as Task;
  return (
    <Box>
      <Table>
        <TableHead>
          <TableRow>
            {bulkEdit && (
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selectedAllResources}
                  color="primary"
                  indeterminate={selectedSomeResources}
                  onChange={handleSelectAllResources}
                />
              </TableCell>
            )}
            {headers.map((h) => (
              <TableCell key={h}>{snakeToSentence(h)}</TableCell>
            ))}
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {resources.map((r) => {
            const isResourceSelected = selectedResources.includes(r.id);
            return (
              <TableRow hover key={nanoid()}>
                {bulkEdit && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isResourceSelected}
                      color="primary"
                      onChange={(event): void =>
                        handleSelectOneResource(event, r.id)
                      }
                      value={isResourceSelected}
                    />
                  </TableCell>
                )}
                {headers.map((header, i) => {
                  if (i === 0) {
                    return (
                      <CellLink
                        key={nanoid()}
                        name={name === "pipeline" ? `tasks/pipelines` : name}
                        firstColValue={r[header]}
                        resourceId={r.id}
                      />
                    );
                  }
                  if (header === "description") {
                    return (
                      <TableCell key={nanoid()}>
                        {r[header] ? truncate(r[header]) : ""}
                      </TableCell>
                    );
                  }
                  if (header === "created_at" || header === "updated_at") {
                    return (
                      <TableCell key={nanoid()}>
                        {formatDate(r[header])}
                      </TableCell>
                    );
                  }
                  return <TableCell key={nanoid()}>{r[header]}</TableCell>;
                })}
                <TableCell align="left" sx={{ width: 280 }}>
                  <Button
                    component={RouterLink}
                    to={`/dashboard/${
                      type
                        ? `${name === "pipeline" && "tasks"}/pipelines`
                        : name
                    }/${r.id}`}
                  >
                    View
                  </Button>
                  {name === "tasks" && (
                    <Button
                      href={`/dashboard/tasks/create/${
                        toTask(r).pipeline
                      }?taskId=${r.id}`}
                    >
                      Copy
                    </Button>
                  )}
                  {
                    // @ts-ignore
                    r.isBatch && (
                      <Button
                        component={RouterLink}
                        to={`/dashboard/tasks/create/${r.id}`}
                      >
                        Create
                      </Button>
                    )
                  }
                  {name === "pipeline" && (
                    <SampleReportButton variant="text" pipelineId={r.id} />
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {(name === "tasks" || name === "instances") && (
        <TablePagination
          count={totalCount}
          page={page}
          onPageChange={handlePageChange}
          rowsPerPage={50}
          rowsPerPageOptions={[50]}
          component="div"
        />
      )}
    </Box>
  );
};

export default ResourceTable;
