import { Sort, SortOption } from "./SortAndFilter";
import { Resource } from "../../../types/resources";

export const getSortOptions = (name?: boolean): SortOption[] => {
  const updatedAtOptions = [
    {
      label: "Last update (newest)",
      value: "updated_at|desc",
    },
    {
      label: "Last update (oldest)",
      value: "updated_at|asc",
    },
  ];
  const nameOptions = [
    {
      label: "Name (Descending)",
      value: "name|desc",
    },
    {
      label: "Name (Ascending)",
      value: "name|asc",
    },
  ];

  return [...updatedAtOptions, ...(name ? nameOptions : [])] as SortOption[];
};
const descendingComparator = (
  a: Resource,
  b: Resource,
  orderBy: string
): number => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

type GetComparator = (a: Resource, b: Resource) => number;
const getComparator = (order: "asc" | "desc", orderBy: string): GetComparator =>
  order === "desc"
    ? (a: Resource, b: Resource): number => descendingComparator(a, b, orderBy)
    : (a: Resource, b: Resource): number =>
        -descendingComparator(a, b, orderBy);

export const applySort = (resources: Resource[], sort: Sort): Resource[] => {
  const [orderBy, order] = sort.split("|") as [string, "asc" | "desc"];
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = resources.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    // @ts-ignore
    const newOrder = comparator(a[0], b[0]);

    if (newOrder !== 0) {
      return newOrder;
    }

    // @ts-ignore
    return a[1] - b[1];
  });

  // @ts-ignore
  return stabilizedThis.map((el) => el[0]);
};
