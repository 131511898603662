import { Box, Typography, Link } from "@mui/material";
import { CSSProperties } from "react";
import { camelToSentence } from "../../../utils/stringTransform";
import ModalCard from "../../ModalCard";

interface SummaryCardProps {
  handleClose: () => void;
  createdAt?: string;
  updatedAt?: string;
  metadata?: string;
  dataTest?: string;
  resourceType?: string;
  id?: string;
  [key: string]: any;
}

const SummaryCard = (props: SummaryCardProps): JSX.Element => {
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  } as CSSProperties;
  const { handleClose, dataTest, metadata, resourceType, ...rest } = props;
  return (
    <div data-test={dataTest} style={modalStyle}>
      <ModalCard handleClose={handleClose}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            minWidth: "50vw",
            flexWrap: "wrap",
          }}
        >
          <Box mb={2}>
            {Object.keys(rest).map((key) =>
              (key === "exitReason" || key === "exitCode") &&
              !rest[key] ? null : (
                <Box
                  sx={{
                    margin: "0 1rem 1rem 0",
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                  key={key}
                >
                  <b>{camelToSentence(key)}: </b>
                  &nbsp; &nbsp;
                  <code
                    style={{
                      fontSize: "115%",
                    }}
                  >
                    {rest[key]}
                  </code>
                </Box>
              )
            )}
          </Box>
          {metadata && (
            <Box>
              <b>Metadata:</b>
              <pre
                style={{
                  maxHeight: "50vh",
                  padding: "1rem",
                  overflow: "auto",
                  borderRadius: 5,
                  whiteSpace: "pre-wrap",
                  background: "rgba(145, 158, 171, 0.24)",
                }}
              >
                {metadata}
              </pre>
            </Box>
          )}
          {resourceType === "storage" && (
            <>
              <blockquote>
                <Typography variant="body1">
                  <b>Note:</b> Your account has a private and secure AWS S3
                  storage bucket allocated to it. You can access this storage
                  bucket from your dashboard, or from your computer using any
                  free or commercial third-party desktop client that supports
                  connecting to private S3 buckets (WinSCP, ExpandDrive,
                  Transmit, etc.). A desktop client is recommended for
                  uploading/downloading large number of files, or folder
                  structures. Following are the credentials you will need to
                  connect to your storage bucket using a third-party client.
                  (See{" "}
                  <Link href="https://neuroscale.readme.io/docs/setup-storage">
                    NeuroScale documentation
                  </Link>{" "}
                  for more details).
                </Typography>
              </blockquote>
            </>
          )}
        </Box>
      </ModalCard>
    </div>
  );
};

export default SummaryCard;
