import { useEffect, useState } from "react";
import { Dialog, DialogContent } from "@mui/material";
import marked from "marked";

interface DialogProps {
  open: boolean;
  onClose: (value: string) => void;
}

const TOSDialog = ({ onClose, open }: DialogProps): JSX.Element => {
  const [markdown, setMarkdown] = useState("");
  useEffect(() => {
    fetch("/static/tos.md")
      .then((res) => res.text())
      .then((r) => setMarkdown(marked(r)));
  }, []);
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogContent>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: markdown }} />
      </DialogContent>
    </Dialog>
  );
};
export default TOSDialog;
