import { useRef, useState } from "react";
import { Link as RouterLink, Navigate, useNavigate } from "react-router-dom";
import Gravatar from "react-gravatar";
import toast from "react-hot-toast";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
  Skeleton,
} from "@mui/material";

import useAuth from "../../hooks/useAuth";
import { CogIcon } from "../../icons";
import { useGetUserQuery } from "../../api/api";
import Cash from "../../icons/Cash";

const AccountPopover = (): JSX.Element => {
  const nav = useNavigate();
  const { logout } = useAuth();
  const { data: user, isLoading, isError, error } = useGetUserQuery();
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      nav("/");
    } catch (err) {
      console.error(err);
      toast.error("Unable to logout.");
    }
  };

  if (isLoading || !user || !user.first_name) {
    return <Skeleton variant="circular" width={32} height={32} />;
  }

  // @ts-ignore
  if (isError || (error && error.status && error.status === 403)) {
    logout();
    return <Navigate to="/" />;
  }
  return (
    <>
      <Box
        data-test="avatar"
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: "center",
          display: "flex",
          borderRadius: "50%",
        }}
      >
        <Avatar sx={{ width: 32, height: 32 }}>
          <Gravatar email={user.email} size={32} />
        </Avatar>
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="subtitle2"
            data-test="account-popover-name"
          >
            {user.first_name}
          </Typography>
          <Typography color="textSecondary" variant="subtitle2">
            NeuroScale
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ mt: 2 }}>
          <MenuItem component={RouterLink} to="/dashboard/usage">
            <ListItemIcon>
              <CogIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  data-test="settings-button"
                  color="textPrimary"
                  variant="subtitle2"
                >
                  Usage
                </Typography>
              }
            />
          </MenuItem>
          <MenuItem component={RouterLink} to="/dashboard/billing">
            <ListItemIcon>
              <Cash fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  data-test="settings-button"
                  color="textPrimary"
                  variant="subtitle2"
                >
                  Billing
                </Typography>
              }
            />
          </MenuItem>
          <MenuItem component={RouterLink} to="/dashboard/account">
            <ListItemIcon>
              <CogIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  data-test="settings-button"
                  color="textPrimary"
                  variant="subtitle2"
                >
                  Settings
                </Typography>
              }
            />
          </MenuItem>
        </Box>
        <Box sx={{ p: 2 }}>
          <Button
            data-test="logout"
            color="primary"
            fullWidth
            onClick={handleLogout}
            variant="outlined"
          >
            Logout
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default AccountPopover;
