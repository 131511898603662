import { Box, Tooltip } from "@mui/material";
import THEMES from "../constants";
import useSettings from "../hooks/useSettings";
import { MoonIcon, SunIcon } from "../icons";

const SettingsDrawer = (): JSX.Element => {
  const { settings, saveSettings } = useSettings();

  const toggleTheme = (): void => {
    saveSettings({
      ...settings,
      theme: settings.theme === THEMES.LIGHT ? THEMES.DARK : THEMES.LIGHT,
    });
  };

  return (
    <Tooltip
      title={
        settings.theme === THEMES.LIGHT
          ? "toggle dark mode"
          : "toggle light mode"
      }
    >
      <Box
        onClick={toggleTheme}
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          top: -15,
          height: 30,
          width: 30,
          borderRadius: "50%",
          background: (theme): string => theme.palette.primary.main,
          margin: (theme): string => theme.spacing(4),
          position: "fixed",
          right: 0,
          zIndex: (theme): number => theme.zIndex.appBar + 201,
        }}
      >
        {settings.theme === THEMES.LIGHT ? (
          <SunIcon fontSize="small" />
        ) : (
          <MoonIcon color="primary" fontSize="small" />
        )}
      </Box>
    </Tooltip>
  );
};

export default SettingsDrawer;
