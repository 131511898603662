import { Resource } from "../../../types/resources";
import { Task } from "../../../types/tasks";
/*
 * resources: Current list of resources displayed in the table
 * query: current input in search text field
 * filters:
 * */
export const applyFilters = (
  resources: Resource[],
  query: string,
  filters: any
): Resource[] =>
  resources.filter((resource) => {
    let matches = true;

    if (query) {
      const properties = ["description", "name"];
      let containsQuery = false;

      properties.forEach((property) => {
        if (
          resource[property] &&
          resource[property].toLowerCase().includes(query.toLowerCase())
        ) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (value && resource[key] !== value) {
        matches = false;
      }
    });

    return matches;
  });

export const applyFilterBy = (resources: Task[], query: string): Resource[] =>
  resources.filter((resource): boolean => {
    if (query === "succeeded") {
      return resource.state === "succeeded" || resource.state === "failed";
    }
    if (query === "running") {
      return (
        resource.state === "running" ||
        resource.state === "runnable" ||
        resource.state === "starting" ||
        resource.state === "submitted" ||
        resource.state === "pending"
      );
    }
    return true;
  });
export const applyPagination = (
  resource: Resource[],
  page: number,
  limit: number
): Resource[] => resource.slice(page * limit, page * limit + limit);
