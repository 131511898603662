import { ChangeEvent, useEffect, useState } from "react";
import {
  Box,
  Card,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { toast } from "react-hot-toast";
import { TabPanel } from "../util/TabPanel";
import { FieldCategory } from "../../types/instances";
import ErrorToast from "../ErrorToast";

// TODO: replace tabbed parameters and param forms with this more generic component?

const TabbedFields = (props: {
  fieldCategories: FieldCategory[];
  dataTest: string;
  debugLog: string;
  debugLogError: any;
}): JSX.Element => {
  const { fieldCategories, dataTest, debugLog, debugLogError } = props;
  const [currentTab, setCurrentTab] = useState<string>(fieldCategories[0].name);
  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    if (value === "Debug" && debugLogError) {
      const instanceId = fieldCategories[0].fields[0].ID;
      toast.error(
        (t): JSX.Element => (
          <ErrorToast
            apiCall={`GET instances/${instanceId}/debug`}
            message={debugLogError.data.Message}
            t={t}
            resourceId={instanceId}
            pipelineId={fieldCategories[0].fields[1].Pipeline}
          />
        ),
        { duration: 999999 }
      );
    }
    setCurrentTab(value);
  };

  useEffect(() => {
    setCurrentTab(fieldCategories[0].name);
  }, [fieldCategories[0]]);
  return (
    <Grid data-test={dataTest}>
      <Tabs
        indicatorColor="primary"
        onChange={handleTabsChange}
        scrollButtons="auto"
        textColor="primary"
        value={currentTab}
        variant="scrollable"
      >
        {fieldCategories &&
          fieldCategories.map((category) => (
            <Tab
              key={category.name}
              label={category.name}
              value={category.name}
            />
          ))}
      </Tabs>
      <Box>
        {fieldCategories.map((cat) => (
          <TabPanel key={cat.name} value={cat.name} index={currentTab}>
            <Box mb={3}>
              <Card sx={{ p: 3 }}>
                {cat.fields &&
                  cat.fields.map((field) => {
                    const key = Object.keys(field)[0];
                    return (
                      <Box
                        key={key}
                        sx={{
                          display: "flex",
                          m: 2,
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography variant="h6">{key}</Typography>
                        &emsp;
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {field.pre ? (
                          <pre
                            style={{
                              padding: "1rem",
                              overflow: "auto",
                              borderRadius: 5,
                              whiteSpace: "pre-wrap",
                              background: "rgba(145, 158, 171, 0.24)",
                              marginTop: -10,
                            }}
                          >
                            {JSON.stringify(field[key], null, 2)}
                          </pre>
                        ) : // eslint-disable-next-line no-nested-ternary
                        field.radio ? (
                          <RadioGroup
                            row
                            defaultValue={field[key] ? "True" : "False"}
                          >
                            <FormControlLabel
                              value="True"
                              control={<Radio disabled />}
                              label="True"
                            />
                            <FormControlLabel
                              value="False"
                              control={<Radio disabled />}
                              label="False"
                            />
                          </RadioGroup>
                        ) : field.debug ? (
                          <pre
                            style={{
                              padding: "1rem",
                              overflow: "auto",
                              borderRadius: 5,
                              whiteSpace: "pre-wrap",
                              background: "rgba(145, 158, 171, 0.24)",

                              marginTop: -10,
                            }}
                          >
                            {debugLog}
                          </pre>
                        ) : (
                          <code
                            style={{
                              padding: "4px 4px 2px 4px ",
                              borderRadius: 5,
                              background: "rgba(145, 158, 171, 0.24)",
                            }}
                          >
                            {field[key] || "None"}
                          </code>
                        )}
                      </Box>
                    );
                  })}
              </Card>
            </Box>
          </TabPanel>
        ))}
      </Box>
    </Grid>
  );
};

export default TabbedFields;
