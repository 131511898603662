import { createSvgIcon } from "@mui/material";

// <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.047,4,22,8.325,9.3,20,2,12.68,6.136,8.533,9.474,11.88Z"/></svg>

const CheckMark = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" fill="#29AB87" viewBox="0 0 24 24">
    <path d="M18.047,4,22,8.325,9.3,20,2,12.68,6.136,8.533,9.474,11.88Z" />
  </svg>,
  "CheckMark"
);

export default CheckMark;
