import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import type { Theme } from "@mui/material";
import {
  Box,
  Button,
  Divider,
  Drawer,
  Link,
  useMediaQuery,
} from "@mui/material";
import Logo from "../Logo";
import NavSection from "../NavSection";
import Scrollbar from "../Scrollbar";

import {
  ChartSquareBarIcon,
  FolderOpenIcon,
  InstanceIcon,
  LockIcon,
} from "../../icons";
import Home from "../../icons/Home";
import { useGetDocsUrlQuery } from "../../api/api";
import TOSDialog from "../TOSDialog";

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const sections = [
  {
    title: "",
    items: [
      {
        title: "Overview",
        path: "/dashboard",
        icon: <Home style={{ marginTop: -5 }} fontSize="small" />,
      },
      {
        title: "Insights",
        icon: <ChartSquareBarIcon fontSize="small" />,
        children: [
          {
            title: "List Tasks",
            path: "/dashboard/tasks",
          },
          {
            title: "Create A Task",
            path: "/dashboard/tasks/create",
          },
          {
            title: "List Pipelines",
            path: "/dashboard/tasks/pipelines",
          },
        ],
      },
      {
        title: "Streaming",
        icon: <InstanceIcon fontSize="small" />,
        children: [
          {
            title: "List Instances",
            path: "/dashboard/instances",
          },
          {
            title: "Create An Instance",
            path: "/dashboard/instances/create",
          },
          {
            title: "List Pipelines",
            path: "/dashboard/instances/pipelines",
          },
        ],
      },
      {
        title: "Authorizations",
        icon: <LockIcon fontSize="small" />,
        children: [
          {
            title: "List Authorizations",
            path: "/dashboard/authorizations",
          },
          {
            title: "Create An Authorization",
            path: "/dashboard/authorizations/create",
          },
        ],
      },
      {
        title: "Storage",
        path: "/dashboard/storage",
        icon: <FolderOpenIcon fontSize="small" />,
      },
    ],
  },
];

const DashboardSidebar = (props: DashboardSidebarProps): JSX.Element => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  const { data, isLoading } = useGetDocsUrlQuery();
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);
  const handleDocRedirect = async (): Promise<void> => {
    const win = window.open(data.docs_url, "_blank");
    if (win != null) {
      win.focus();
    }
  };
  const [open, setOpen] = useState(false);
  const handleTOSClick = (): void => {
    setOpen(true);
  };
  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: "none",
              xs: "flex",
            },
            justifyContent: "center",
            p: 2,
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 30,
                color: (theme): any => theme.palette.primary.dark,
              }}
            />
          </RouterLink>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                "& + &": {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {!isLoading && data && data.docs_url && (
            <Button
              color="primary"
              fullWidth
              sx={{ mt: 2 }}
              onClick={handleDocRedirect}
              variant="contained"
            >
              Documentation
            </Button>
          )}
        </Box>
      </Scrollbar>
      <Link
        sx={{
          mb: 1,
          display: "flex",
          justifyContent: "center",
          cursor: "pointer",
          fontSize: 12,
        }}
        onClick={handleTOSClick}
      >
        Terms of Service
      </Link>
      <TOSDialog open={open} onClose={(): void => setOpen(false)} />
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "background.paper",
            height: "calc(100% - 64px) !important",
            top: "64px !Important",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: "background.paper",
          width: 280,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

export default DashboardSidebar;
