import { Helmet } from "react-helmet-async";
import {
  Box,
  Button,
  Container,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import useSettings from "../../hooks/useSettings";
import {
  useDeleteAuthorizationMutation,
  useGetAuthorizationsQuery,
} from "../../api/api";
import ResourceList from "../../components/dashboard/resourceList/ResourceList";

const AuthorizationList = (): JSX.Element => {
  const { settings } = useSettings();
  const { data: authorizations, isLoading } = useGetAuthorizationsQuery();
  const [deleteAuthorization, { isLoading: isDeleting }] =
    useDeleteAuthorizationMutation();

  const navigate = useNavigate();
  const handleCreate = (): void => {
    navigate("/dashboard/authorizations/create");
  };
  return (
    <>
      <Helmet>
        <title>Dashboard: Pipeline List | NeuroScale</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 8,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Authorization List
              </Typography>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <Button
                  onClick={handleCreate}
                  sx={{ m: 1 }}
                  variant="contained"
                >
                  Create Authorization
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            {isLoading || isDeleting ? (
              <Skeleton
                sx={{
                  width: "100%",
                  height: 600,
                  marginTop: -15,
                }}
              />
            ) : (
              <ResourceList
                resourceType="authorizations"
                headers={[
                  "id",
                  "description",
                  "access_token",
                  "updated_at",
                  "created_at",
                ]}
                bulkEdit
                resources={authorizations}
                deleteResource={deleteAuthorization}
              />
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default AuthorizationList;
