import { Helmet } from "react-helmet-async";
import { format } from "date-fns";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  List,
  ListItem,
  Skeleton,
  Typography,
  Button,
  TextField,
} from "@mui/material";
import { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import BillingCardForm from "../../components/dashboard/account/BillingCardForm";
import useSettings from "../../hooks/useSettings";
import BillingCardTable from "../../components/dashboard/account/BillingCardTable";
import { useGetCardQuery, getInvoices } from "../../api/api";

const Billing = (): JSX.Element => {
  const { settings } = useSettings();
  const [mode, setMode] = useState("Add");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [authorized, setAuthorized] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const { data, isLoading, error: billingError } = useGetCardQuery();
  useEffect(() => {
    if (data) {
      setMode("View");
    }
  }, [data, invoices]);

  const handleSubmit = async (): Promise<void> => {
    setDisabled(true);
    const res = await getInvoices(username, password);
    setDisabled(false);
    if (!res) {
      setError("Bad username or password");
    } else {
      setInvoices(res);
      setAuthorized(true);
    }
  };

  if (isLoading) {
    return (
      <Skeleton variant="rectangular" sx={{ height: 140, borderRadius: 1 }} />
    );
  }
  // @ts-ignore
  if (billingError && !billingError.data.includes(400)) {
    console.error(billingError);
    return <Box p={2}>Billing info unavailable.</Box>;
  }
  return (
    <>
      <Helmet>
        <title>Dashboard: Invoice History | NeuroScale</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 6,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          mx: 16,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid data-test="overview" container spacing={3}>
            <Grid
              alignItems="center"
              container
              justifyContent="space-between"
              spacing={3}
              item
              xs={12}
            >
              <Grid item>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    mb: 2,
                  }}
                >
                  <Typography color="textPrimary" variant="h5">
                    Billing
                  </Typography>
                  <Button
                    variant="contained"
                    component={RouterLink}
                    to="/dashboard/usage"
                  >
                    Usage
                  </Button>
                </Box>
                <Typography color="textSecondary" variant="subtitle2">
                  The card you enter below will be charged monthly (based on the
                  day of the month that your account is first active), for the
                  NCUs used that month in excess of your prepaid NCU balance, as
                  shown on your <a href="usage">Neuroscale Usage</a> page. (Your
                  NCU prepaid balance are NCUs that you purchased ahead of time
                  or that were added to your account by Intheon.) Your credit
                  card payments are processed by Stripe (a highly rated payments
                  processing company), who keeps a record of your credit card
                  information. Intheon does not keep a copy of your credit card
                  information on file.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Card sx={{ p: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <CardHeader title="Card Details" />
              </Box>
              <CardContent>
                {!mode && (
                  <Skeleton
                    sx={{ borderRadius: 2 }}
                    width="100%"
                    height={300}
                    variant="rectangular"
                  />
                )}
                {mode === "View" && (
                  <BillingCardTable setMode={setMode} cardInfo={data} />
                )}
                {(mode === "Add" || mode === "Update") && (
                  <BillingCardForm mode={mode} setMode={setMode} />
                )}
              </CardContent>
            </Card>
          </Box>
          <Box mt={3}>
            <Card sx={{ p: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <CardHeader title="Invoice History" />
              </Box>
              <CardContent>
                Invoices are generated automatically at the end of each month
                and charged to your credit card on file (as shown on this page).
                Following is the invoice history for your account.
              </CardContent>
              <CardContent>
                <List
                  sx={{
                    border: "1px solid rgb(230, 232, 240)",
                    borderRadius: 1,
                  }}
                >
                  {!authorized ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        p: 3,
                      }}
                    >
                      <Typography sx={{ mb: 2 }}>
                        Please re-enter your credentials to get your invoices.
                      </Typography>
                      <TextField
                        name="username"
                        placeholder="username"
                        value={username}
                        onChange={({ target: { value } }): void =>
                          setUsername(value)
                        }
                        sx={{ mb: 3 }}
                      />
                      <TextField
                        name="password"
                        type="password"
                        placeholder="password"
                        value={password}
                        onChange={({ target: { value } }): void =>
                          setPassword(value)
                        }
                        sx={{ mb: 3 }}
                      />
                      <Button
                        disabled={disabled}
                        onClick={handleSubmit}
                        variant="contained"
                      >
                        Submit
                      </Button>
                      <Typography>{error}</Typography>
                    </Box>
                  ) : (
                    invoices.map((invoice) => (
                      <ListItem key={invoice.date} sx={{ p: 2 }}>
                        <Typography
                          variant="subtitle2"
                          style={{ fontWeight: "bolder", minWidth: 180 }}
                        >
                          <a href={invoice.invoice_pdf}>
                            {format(
                              new Date(invoice.date * 1000),
                              "dd LLL yyyy"
                            )}
                          </a>
                        </Typography>
                      </ListItem>
                    ))
                  )}
                </List>
              </CardContent>
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  );
};
export default Billing;
