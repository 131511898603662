import { Tab, Tabs } from "@mui/material";
import type { ChangeEvent } from "react";

export interface TabOption {
  value: string;
  label: string;
}
interface ResourceTabsProps {
  handleTabsChange: (e: ChangeEvent<HTMLInputElement>, value: string) => void;
  currentTab: string;
  tabs: TabOption[];
}

const ResourceTabs = (props: ResourceTabsProps): JSX.Element => {
  const { handleTabsChange, currentTab, tabs } = props;

  return (
    <Tabs
      indicatorColor="primary"
      onChange={handleTabsChange}
      scrollButtons="auto"
      textColor="primary"
      value={currentTab}
      variant="scrollable"
    >
      {tabs.map((tab) => (
        <Tab key={tab.value} label={tab.label} value={tab.value} />
      ))}
    </Tabs>
  );
};
export default ResourceTabs;
