import { ChangeEvent, Dispatch } from "react";
import { Box, Card, Checkbox, Divider, Typography } from "@mui/material";
import { camelToSentence } from "../../../utils/stringTransform";
import type { Scopes } from "../../../types/authorization";

interface ScopeCardProps {
  resource: string;
  scopes: Scopes;
  setScopes: Dispatch<any>;
}

const ScopeCard = ({
  resource,
  scopes,
  setScopes,
}: ScopeCardProps): JSX.Element => {
  const handleSelect = (
    { target: { checked } }: ChangeEvent<HTMLInputElement>,
    action: string
  ): void => {
    if (action === "all") {
      setScopes({
        ...scopes,
        [resource]: {
          all: checked,
          create: checked,
          read: checked,
          update: checked,
          delete: checked,
          list: checked,
        },
      });
    } else {
      setScopes((prevMap) => ({
        ...prevMap,
        [resource]: { ...prevMap[resource], [action]: checked },
      }));
    }
  };
  // TODO: have Formik manage this form
  return (
    <Card
      sx={{
        p: 4,
        mr: 3,
        mb: 3,
      }}
    >
      <Typography variant="overline">{resource}</Typography>
      <Divider />
      <Box sx={{ mt: 1, ml: -1 }}>
        <Checkbox
          disabled={resource === "user"}
          checked={scopes[resource].all}
          onChange={(e): void => handleSelect(e, "all")}
        />{" "}
        All Operations
      </Box>
      {["create", "read", "update", "delete", "list"].map((action) => (
        <Box key={action}>
          &emsp;{" "}
          <Checkbox
            disabled={resource === "user"}
            checked={scopes[resource][action]}
            onChange={(e): void => handleSelect(e, action)}
          />
          {camelToSentence(action)} {resource}
        </Box>
      ))}
    </Card>
  );
};
export default ScopeCard;
