/* eslint-disable react/no-unused-prop-types */
import { Box, Divider } from "@mui/material";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { ChonkyIconFA } from "chonky-icon-fontawesome";
import { FileWithPath } from "file-selector";
import { useImmer } from "use-immer";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useLocation } from "react-router";
import useSettings from "../../../hooks/useSettings";
import {
  ChonkyActions,
  ChonkyFileActionData,
  ChonkyIconName,
  defineFileAction,
  FileArray,
  FileBrowser as Browser,
  FileContextMenu,
  FileData,
  FileList,
  FileNavbar,
  FileToolbar,
} from "./chonky";

import { Storage } from "../../../types/storage";
import { getCurrentFiles, getFolderChain } from "./helpers";
import S3 from "./S3";
import handleFileAction, { formatPath } from "./handleFileAction";
import DestroyConfirmationModal from "./DestroyConfirmationModal";
import UploadingCard from "./UploadingCard";
import FormModal from "./FormModal";
import UploadModal from "./UploadModal";
import useAuth from "../../../hooks/useAuth";

const RenameFileAction = defineFileAction({
  id: "rename_file",
  requiresSelection: true,
  button: {
    name: "Rename",
    contextMenu: true,
    icon: ChonkyIconName.text,
  },
});

const CreateTaskAction = defineFileAction({
  id: "create_task",
  requiresSelection: true,
  fileFilter: (f) => !f.isDir,
  button: {
    name: "Task",
    tooltip: "Create a Task from a File",
    contextMenu: true,
    icon: ChonkyIconName.symlink,
    toolbar: true,
  },
});
const fileActions = [
  ChonkyActions.DeleteFiles,
  ChonkyActions.CopyFiles,
  RenameFileAction,
  ChonkyActions.CreateFolder,
  ChonkyActions.UploadFiles,
  ChonkyActions.DownloadFiles,
  ChonkyActions.MouseClickFile,
  CreateTaskAction,
];

const FileBrowser = (props: Storage): JSX.Element => {
  // vars
  const { bucket } = props;
  const s3 = new S3(props);

  // hooks
  const { restrictedExtensions } = useAuth();
  const nav = useNavigate();
  const [currentFolderId, setCurrentFolderId] = useState<string>("/");
  const [files, setFiles] = useImmer<FileArray>([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [createModalValue, setCreateModalValue] = useState("");
  const [renameModal, setRenameModal] = useState(false);
  const [renameModalValue, setRenameModalValue] = useState("");
  const [copyModal, setCopyModal] = useState(false);
  const [copyModalValue, setCopyModalValue] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [fileToUpdate, setFileToUpdate] = useState<FileData>();
  const [idsToDelete, setIdsToDelete] = useState([]);
  const [uploadModal, setUploadModal] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<FileWithPath[]>([]);
  const [uploadCard, setUploadCard] = useState<boolean>(false);
  const [compressing, setCompressing] = useState<boolean>(false);
  const { settings } = useSettings();
  const params = new URLSearchParams(useLocation().search);
  const io = String(params.getAll("io"));
  useEffect(() => {
    if (io) {
      setCurrentFolderId(`${io}/`);
      nav("/dashboard/storage");
    }
    getCurrentFiles(s3, currentFolderId).then(setFiles);
  }, [currentFolderId]);
  const folderChain = useMemo(
    () => getFolderChain(currentFolderId, bucket),
    [currentFolderId, setCurrentFolderId]
  );
  // TODO: DRY all this up
  const onFileAction = async (data: ChonkyFileActionData): Promise<void> =>
    handleFileAction(
      s3,
      data,
      currentFolderId,
      setCurrentFolderId,
      setFiles,
      setDeleteModal,
      setIdsToDelete,
      setFileToUpdate,
      setCopyModal,
      setRenameModal,
      setRenameModalValue,
      setCreateModal,
      setUploadModal,
      props.setSelectedPath,
      nav,
      restrictedExtensions,
      setCompressing
    );
  // delete
  const handleDeleteModalClose = (): void => {
    setDeleteModal(false);
  };
  const handleModalDelete = async (): Promise<void> => {
    await s3.recursiveDelete(idsToDelete);
    getCurrentFiles(s3, currentFolderId).then(setFiles);
    handleDeleteModalClose();
  };
  const handleRefetch = (): void => {
    getCurrentFiles(s3, currentFolderId).then(setFiles);
  };
  // copy
  const handleCopyModalClose = (): void => {
    setCopyModal(false);
    setCopyModalValue("");
    setErrorMsg("");
  };
  const handleModalCopyValue = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>): void => {
    setCopyModalValue(value);
  };

  const handleModalCopy = async (): Promise<void> => {
    const copyVal = fileToUpdate.isDir ? `${copyModalValue}/` : copyModalValue;
    if (fileToUpdate.name === copyVal) {
      setErrorMsg("Cannot be the same name");
    } else {
      const currentKey = fileToUpdate.id;
      const updatedKey = `${formatPath(currentFolderId)}${copyVal}`;
      await s3.recursiveCopy(currentKey, updatedKey);
      getCurrentFiles(s3, currentFolderId).then(setFiles);
      handleCopyModalClose();
    }
  };

  // rename
  const handleRenameModalClose = (): void => {
    setRenameModal(false);
    setErrorMsg("");
  };
  const handleModalRenameValue = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>): void => {
    setRenameModalValue(value);
  };

  const handleModalRename = async (): Promise<void> => {
    const renameVal = fileToUpdate.isDir
      ? `${renameModalValue}/`
      : renameModalValue;
    if (fileToUpdate.name === renameVal) {
      setErrorMsg("Cannot be the same name");
    } else {
      const currentKey = fileToUpdate.id;
      const updatedKey = `${formatPath(currentFolderId)}${renameVal}`;
      try {
        await s3.recursiveCopy(currentKey, updatedKey);
        await s3.recursiveDelete([currentKey]);
        getCurrentFiles(s3, currentFolderId).then(setFiles);
        handleRenameModalClose();
      } catch {
        setErrorMsg("Something went wrong. Please try again.");
      }
    }
  };

  // create folder
  const handleModalCreateValue = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>): void => {
    setCreateModalValue(value);
  };
  const handleCreateModalClose = (): void => {
    setCreateModal(false);
    setCreateModalValue("");
    setErrorMsg("");
  };

  const handleModalCreate = async (): Promise<void> => {
    if (createModalValue.includes("/")) {
      setErrorMsg("New folder names cannot include '/'");
    } else {
      const newPathId = `${
        currentFolderId === "/" ? "" : currentFolderId
      }${createModalValue}/`;
      await s3.putObject(newPathId);
      getCurrentFiles(s3, currentFolderId).then(setFiles);
      handleCreateModalClose();
    }
  };

  // upload
  const handleUploadModalClose = (): void => {
    setUploadModal(false);
    setUploadedFiles([]);
  };
  const handleDrop = (acceptedFiles): void => {
    setUploadedFiles(acceptedFiles);
  };
  const [percentages, setPercentages] = useImmer<
    { name: string; percent: number }[]
  >([]);
  const handleUpload = async (): Promise<void> => {
    handleUploadModalClose();
    setUploadCard(true);
    const prefix = currentFolderId === "/" ? "" : currentFolderId;
    setPercentages(uploadedFiles.map((f) => ({ name: f.path, percent: 0 })));
    await Promise.all(
      uploadedFiles.map(async (fileToUpload) => {
        const upload = s3.upload(
          `${prefix}${
            fileToUpload.path[0] === "/"
              ? fileToUpload.path.replace("/", "")
              : fileToUpload.path
          }`,
          fileToUpload
        );
        upload.on("httpUploadProgress", (progress) => {
          const percent = Math.round((progress.loaded / progress.total) * 100);
          setPercentages((draft) => {
            draft.find((p) => p.name === fileToUpload.path).percent = percent;
          });
        });
        await upload.done();
      })
    );

    setUploadCard(false);
    toast.success("Upload finished!");
    getCurrentFiles(s3, currentFolderId).then(setFiles);
  };

  return (
    <>
      <Browser
        darkMode={settings.theme === "DARK"}
        iconComponent={ChonkyIconFA}
        onFileAction={onFileAction}
        defaultFileViewActionId="enable_list_view"
        folderChain={folderChain}
        files={files}
        fileActions={fileActions}
      >
        <Box sx={{ p: 1.5 }}>
          <FileToolbar handleRefetch={handleRefetch} />
        </Box>
        <Divider />
        <Box sx={{ pt: 1 }}>
          <FileNavbar />
        </Box>
        <Box sx={{ px: 3, height: "100%" }}>
          <FileList />
        </Box>
        <FileContextMenu />
      </Browser>
      <FormModal
        handleModalClose={handleCopyModalClose}
        errorMsg={errorMsg}
        modal={copyModal}
        handleModalValue={handleModalCopyValue}
        modalValue={copyModalValue}
        handleSubmit={handleModalCopy}
      />
      <FormModal
        handleModalClose={handleRenameModalClose}
        errorMsg={errorMsg}
        modal={renameModal}
        handleModalValue={handleModalRenameValue}
        modalValue={renameModalValue}
        handleSubmit={handleModalRename}
      />
      <FormModal
        errorMsg={errorMsg}
        handleModalClose={handleCreateModalClose}
        modal={createModal}
        handleModalValue={handleModalCreateValue}
        modalValue={createModalValue}
        handleSubmit={handleModalCreate}
        isCreate
      />
      <DestroyConfirmationModal
        idsToDelete={idsToDelete}
        handleDeleteModalClose={handleDeleteModalClose}
        deleteModal={deleteModal}
        handleModalDelete={handleModalDelete}
      />
      <UploadModal
        handleModalClose={handleUploadModalClose}
        modal={uploadModal}
        handleDrop={handleDrop}
        files={uploadedFiles}
        handleUpload={handleUpload}
      />
      {uploadCard && <UploadingCard percentages={percentages} />}
      {compressing && <UploadingCard />}
    </>
  );
};

export default FileBrowser;
