import { useEffect, useState } from "react";
import { Box, Button, Grid, Skeleton } from "@mui/material";
import { useParams } from "react-router";
import { useImmer } from "use-immer";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Pipeline } from "../../types/pipeline";
import DashPageLayout from "../../components/dashboard/DashPageLayout";
import Header from "../../components/dashboard/task/Header";
import {
  useCreateInstanceMutation,
  useGetPipelineQuery,
  useGetPipelinesQuery,
} from "../../api/api";
import SelectPipeline from "../../components/dashboard/task/SelectPipeline";
import ProgressScreen from "../../components/ProgressScreen";
import PipelineOverview from "../../components/dashboard/pipeline/PipelineOverview";
import TabbedParameterForms from "../../components/dashboard/task/TabbedParameterForms";
import { Instance } from "../../types/instances";
import NotFound from "../NotFound";
import JSONDrawer from "../../components/dashboard/JSONDrawer";

const CreateInstance = (): JSX.Element => {
  // hooks
  const { pipelineId } = useParams();
  const [currentPipeline, setCurrentPipeline] = useState<Pipeline>({
    id: "",
    name: "",
  });
  const nav = useNavigate();
  const { data: pipelines, isLoading: arePipelinesLoading } =
    useGetPipelinesQuery();

  const [categories, setCategories] = useImmer([]);
  const {
    data: pipelineFromRoute,
    isLoading: isPipelineLoading,
    error: getPipelineError,
  } = useGetPipelineQuery(pipelineId);
  useEffect(() => {
    if (pipelineFromRoute) {
      setCurrentPipeline(pipelineFromRoute);
    }
    if (currentPipeline) {
      setCategories(currentPipeline.categories);
    }
  }, [pipelineFromRoute, currentPipeline]);

  const [createInstance] = useCreateInstanceMutation();
  const [drawer, setDrawer] = useState(false);

  // handlers
  const toggleDrawer = (): void => {
    setDrawer(!drawer);
  };
  const handleCreate = async (): Promise<void> => {
    const parameters = categories
      .map((c) => c.params)
      .flat()
      .reduce((a, c) => ({ ...a, [c.name]: c.value }), {});

    const { data: instance } = (await createInstance({
      pipeline: pipelineId,
      parameters,
    })) as { data: Instance };
    if (instance) {
      toast.success(
        `Instance Successfully Created (${instance.id})! Please check in the list of running instances.`
      );
      setTimeout(() => {
        nav("/dashboard/instances");
      }, 2000);
    }
  };

  if (
    isPipelineLoading ||
    (!pipelineId && categories && categories.length < 0)
  ) {
    return <ProgressScreen />;
  }

  if (getPipelineError && pipelineId) {
    return <NotFound />;
  }

  return (
    <DashPageLayout resource="Instance">
      <>
        <Header title="Run a Streaming Pipeline (Create Instance)">
          <>
            <Button
              onClick={handleCreate}
              sx={{ m: 1 }}
              data-test="instance-create-button"
              variant="contained"
              disabled={!pipelineId}
            >
              Create Instance
            </Button>
            <Button onClick={toggleDrawer} variant="contained">
              JSON
            </Button>
          </>
        </Header>
        <Box sx={{ mt: 3 }}>
          <Grid container>
            <Box sx={{ width: "100%" }}>
              {arePipelinesLoading ? (
                <Skeleton height={80} width="100%" />
              ) : (
                <SelectPipeline
                  streamsOnly
                  setCurrentPipeline={setCurrentPipeline}
                  pipelines={pipelines}
                  pipelineId={pipelineId}
                />
              )}
            </Box>
          </Grid>
          {pipelineId && currentPipeline.description && (
            <PipelineOverview
              name={currentPipeline.name}
              description={currentPipeline.description}
            />
          )}
          {pipelineId &&
            currentPipeline &&
            categories &&
            categories.length > 0 && (
              <TabbedParameterForms
                setCategories={setCategories}
                categories={categories}
              />
            )}
        </Box>

        <JSONDrawer
          setCategories={setCategories}
          categories={categories}
          open={drawer}
          onClose={toggleDrawer}
        />
      </>
    </DashPageLayout>
  );
};

export default CreateInstance;
