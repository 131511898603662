import { Box, InputAdornment, TextField } from "@mui/material";
import { ChangeEvent } from "react";
import { SearchIcon } from "../../../icons";

export type Sort =
  | "updated_at|desc"
  | "updated_at|asc"
  | "name|desc"
  | "name|asc";

export type FilterBy = "all" | "running" | "succeeded";
export interface SortOption {
  value: Sort;
  label: string;
}

export interface FilterByOption {
  value: FilterBy;
  label: string;
}
interface SortAndFilterProps {
  name: string;
  query: string;
  sort: string;
  filterBy: string;
  filterByOptions: FilterByOption[];
  sortOptions: SortOption[];
  handleQueryChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSortChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleFilterByChange: (event: ChangeEvent<HTMLInputElement>) => void;
}
const SortAndFilter = (props: SortAndFilterProps): JSX.Element => {
  const {
    handleQueryChange,
    query,
    name,
    handleSortChange,
    sort,
    sortOptions,
    handleFilterByChange,
    filterBy,
    filterByOptions,
  } = props;
  return (
    <>
      <Box
        sx={{
          m: 1,
          maxWidth: "100%",
          width: 500,
        }}
      >
        <TextField
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            ),
          }}
          onChange={handleQueryChange}
          placeholder="Search pipelines"
          value={query}
          variant="outlined"
        />
      </Box>
      <Box
        sx={{
          m: 1,
        }}
      >
        <TextField
          label="Sort By"
          name="sort"
          onChange={handleSortChange}
          select
          SelectProps={{ native: true }}
          value={sort}
          variant="outlined"
          sx={{ mr: 2 }}
        >
          {sortOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
        {name === "tasks" && (
          <TextField
            label="Filter By State"
            name="filter"
            onChange={handleFilterByChange}
            select
            SelectProps={{ native: true }}
            value={filterBy}
            variant="outlined"
          >
            {filterByOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
        )}
      </Box>
    </>
  );
};
export default SortAndFilter;
