import * as _ from "lodash";
import { Parameter, Pipeline } from "../types/pipeline";
import {
  APIAuthorization,
  Authorization,
  Scopes,
} from "../types/authorization";
import { APIInstance, FieldCategory, Instance } from "../types/instances";

const resources = ["task", "instance", "user", "pipeline"];
const actions = ["all", "create", "list", "read", "update", "delete"];

// numpy.org/doc/stable/reference/generated/numpy.zeros.html
export const zeros = resources.reduce(
  (accA, currA) => ({
    ...accA,
    [currA]: actions.reduce((accB, currB) => ({ ...accB, [currB]: false }), {}),
  }),
  {}
) as Scopes;

export const scopeParser = {
  out: (scopes: string[]): Scopes => {
    const nestedArray = scopes.map((s) => s.replace("*", "all").split(":"));
    const mappedArray = nestedArray.map((val) => ({
      resource: val[0],
      action: val[1],
    }));

    const inner = (a, c): unknown => ({ ...a, [c.action]: true });
    const res = nestedArray.reduce(
      (a, c) => ({
        ...a,
        [c[0]]: mappedArray
          .filter(({ resource }) => c[0] === resource)
          .reduce(inner, {}),
      }),
      {}
    ) as Scopes;

    return _.merge(zeros, res);
  },
  in: (resourceMap: Scopes): string[] => {
    const resourcesArray = Object.keys(resourceMap).map((resource) => ({
      name: resource,
      actions: Object.keys(resourceMap[resource]).filter(
        (key) => resourceMap[resource][key]
      ),
    }));
    const res = resourcesArray.map(({ name, actions: actions2 }) => {
      if (actions2.includes("all")) {
        return `${name}:*`;
      }

      return actions2.map((action) => `${name}:${action}`);
    });
    return res.flat();
  },
};

export const transformPipeline = {
  out: (p: Pipeline): Pipeline => {
    if (p.type === "batch") {
      p.isBatch = true;
    }
    if (p.type === "stream") {
      p.isStream = true;
    }

    const normalParams: Parameter[] = Object.keys(p.parameters).map((key) => ({
      ...p.parameters[key],
      name: key,
      category:
        p.parameters[key].category === ""
          ? "Uncategorized"
          : p.parameters[key].category,
    }));

    const categoryList = [...new Set(normalParams.map((np) => np.category))];

    p.categories = categoryList.map((c) => ({
      name: c,
      params: normalParams.filter((np1) => np1.category === c),
    }));
    return p;
  },
};

export const transformAuthorization = {
  out: (a: APIAuthorization): Authorization => ({
    ...a,
    scopes: scopeParser.out(a.scopes),
  }),
};

export const transformInstance = {
  out: (i: APIInstance): Instance => ({
    ...i,
    calibrating: i.is_calibrating ? "Yes" : "No",
    receiving: i.is_receiving_data ? "Yes" : "No",
    publishing: i.is_publishing_data ? "Yes" : "No",
  }),
};

export const transformInstanceView = (i: APIInstance): FieldCategory[] => [
  {
    name: "Basic",
    fields: [
      { ID: i.id },
      { Pipeline: i.pipeline },
      { State: i.state },
      { "Receiving Data": i.is_receiving_data ? "Yes" : "No" },
      { "Publishing Data": i.is_publishing_data ? "Yes" : "No" },
      { Calibrating: i.is_calibrating ? "Yes" : "No" },
      { Description: i.description },
      { "Created At": i.created_at },
      { Parameters: i.parameters, pre: true },
    ],
  },
  {
    name: "Advanced",
    fields: [
      { MetaData: i.metadata, pre: true },
      { Encoding: i.encoding },
      { "Replace NaNs": i.replace_nans, radio: true },
      { "Replace NaNs With": i.replace_nans_with },
      { "Time to Live": i.time_to_live },
    ],
  },
  {
    name: "Endpoints",
    fields: [{ Endpoints: i.endpoints, pre: true }],
  },
  {
    name: "Debug",
    fields: [
      {
        Log: "",
        debug: true,
      },
    ],
  },
];
