import { ChangeEvent, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { useParams, useLocation } from "react-router";
import { useImmer } from "use-immer";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Category, Pipeline } from "../../types/pipeline";
import DashPageLayout from "../../components/dashboard/DashPageLayout";
import Header from "../../components/dashboard/task/Header";
import {
  useCreateTaskMutation,
  useGetPipelineQuery,
  useGetPipelinesQuery,
  useGetTaskQuery,
} from "../../api/api";
import SelectPipeline from "../../components/dashboard/task/SelectPipeline";
import ProgressScreen from "../../components/ProgressScreen";
import NotFound from "../NotFound";
import PipelineOverview from "../../components/dashboard/pipeline/PipelineOverview";
import TabbedParameterForms from "../../components/dashboard/task/TabbedParameterForms";
import { Task } from "../../types/tasks";
import JSONDrawer from "../../components/dashboard/JSONDrawer";
import ErrorToast from "../../components/ErrorToast";

const isValidPipeline = (categories: Category[]): boolean => {
  const flatArray = categories.map(({ params }) => params).flat();
  const bools = flatArray.map((p): boolean => {
    if (p.type !== "string") {
      if ((typeof p.value).startsWith(p.type)) {
        return true;
      }
      if (p.type.startsWith("list") && Array.isArray(p.value)) {
        return true;
      }
      if (
        p.type.startsWith("dict") &&
        typeof p.value === "object" &&
        p.value !== null
      ) {
        return true;
      }
      if (
        (p.type === "int" || p.type === "float") &&
        typeof p.value === "number"
      ) {
        return true;
      }
    }
    return false;
  });
  return bools.some((v) => v);
};

const CreateTask = (): JSX.Element => {
  const [invalidCategory, setInvalidCategory] = useState<string>("");
  const [disableButton, setDisableButton] = useState(false);
  const { pipelineId } = useParams();
  const [currentPipeline, setCurrentPipeline] = useState<Pipeline>({
    id: "",
    name: "",
  });

  const { data: pipelines, isLoading: arePipelinesLoading } =
    useGetPipelinesQuery();

  const [categories, setCategories] = useImmer([]);
  const {
    data: pipelineFromRoute,
    error: getPipelineError,
    isLoading: isPipelineLoading,
  } = useGetPipelineQuery(pipelineId);

  const [drawer, setDrawer] = useState(false);
  const [taskName, setTaskName] = useState("");
  const params = new URLSearchParams(useLocation().search);
  const incomingFiles = String(params.getAll("file"));
  const [files] = useState(incomingFiles);
  const taskId = String(params.getAll("taskId"));
  let copiedTask: any;
  if (taskId) {
    const { data: task } = useGetTaskQuery(taskId);
    if (task) {
      copiedTask = task;
    }
  }

  const nav = useNavigate();
  useEffect(() => {
    if (pipelineFromRoute) {
      console.info("pipeline from route");
      setCurrentPipeline(pipelineFromRoute);
      setCategories(pipelineFromRoute.categories);
      setTaskName(`Task: ${pipelineFromRoute.name}`);
      if (copiedTask) {
        console.info("wait no a copied task is on the qs");
        const updatedCats = pipelineFromRoute.categories.map((c) => ({
          name: c.name,
          params: c.params.map((p) => {
            const keys = Object.keys(copiedTask.parameters);
            const commonKey = keys.find((k) => k === p.name);
            if (commonKey) {
              return { ...p, value: copiedTask.parameters[commonKey] };
            }
            return p;
          }),
        }));
        setCategories(updatedCats);
        setTaskName(`${copiedTask.name} (copy)`);
      }
    } else if (currentPipeline) {
      console.info("this came in from a selection");
      setCategories(currentPipeline.categories);
      setTaskName(`Task: {currentPipeline.name}`);
    }

    if (files && categories && categories.length === 0) {
      console.info("we got files but no selection");
      toast.success(
        <Box sx={{ wordWrap: "break-word", width: 300 }}>
          Select a Pipeline and your selected files will be applied to it:
          {files}
        </Box>,
        { duration: 5000 }
      );
    }
    if (files !== "" && categories && categories.length > 0) {
      console.info("we got files and a selection");
      setCategories(() => {
        // noinspection UnnecessaryLocalVariableJS
        const res = categories.map((c) => ({
          ...c,
          params: c.params.map((p) => {
            if (p.name === "input_path" || p.name === "input_file") {
              return { ...p, value: files };
            }
            return p;
          }),
        }));
        return res;
      });
    }
  }, [pipelineFromRoute, currentPipeline, copiedTask]);

  const [createTask, { error }] = useCreateTaskMutation();
  if (error) {
    toast.error(
      (t): JSX.Element => (
        <ErrorToast
          message={
            // @ts-ignore
            error.data.Message
          }
          t={t}
          resourceId={taskId}
          pipelineId={currentPipeline.id}
          apiCall="POST /tasks"
        />
      ),
      { duration: 999999 }
    );
    console.error(error);
  }
  // handlers
  const handleCreate = async (): Promise<void> => {
    setDisableButton(true);
    const parameters = categories
      .map((c) => c.params)
      .flat()
      .reduce((a, c) => ({ ...a, [c.name]: c.value }), {});

    const { data: task } = (await createTask({
      pipeline: pipelineId,
      parameters,
      name: taskName,
    })) as { data: Task };
    if (task) {
      toast.success(
        `Task Successfully Created (${task.id})! Please check in the list of running tasks.`,
        { duration: 5000 }
      );
      setTimeout(() => {
        nav("/dashboard/tasks");
      }, 2000);
    }
  };

  const handleTaskNameValue = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>): void => {
    setTaskName(value);
  };
  const toggleDrawer = (): void => {
    setDrawer(!drawer);
  };
  // returns
  if (
    isPipelineLoading ||
    (!pipelineId && categories && categories.length < 0)
  ) {
    return <ProgressScreen />;
  }

  if (getPipelineError && pipelineId) {
    return <NotFound />;
  }
  return (
    <DashPageLayout>
      <>
        <Header title="Run an Insights Pipeline (Create Task)">
          <>
            <Button
              onClick={handleCreate}
              sx={{ m: 1 }}
              data-test="task-create-button"
              variant="contained"
              disabled={
                !pipelineId || Boolean(invalidCategory) || disableButton
              }
            >
              Create Task
            </Button>
            <Button
              disabled={!pipelineId || Boolean(invalidCategory)}
              variant="contained"
              onClick={toggleDrawer}
            >
              JSON
            </Button>
          </>
        </Header>
        <Box sx={{ mt: 3 }}>
          <Grid container>
            <Box sx={{ width: "100%" }}>
              {arePipelinesLoading ? (
                <Skeleton height={80} width="100%" />
              ) : (
                <SelectPipeline
                  setCurrentPipeline={setCurrentPipeline}
                  pipelines={pipelines}
                  pipelineId={pipelineId}
                />
              )}
            </Box>
          </Grid>
          {pipelineId && currentPipeline.description && (
            <PipelineOverview
              name={currentPipeline.name}
              description={currentPipeline.description}
            />
          )}

          {pipelineId &&
            currentPipeline &&
            categories &&
            categories.length > 0 && (
              <>
                <Box mt={6} mb={2}>
                  <Typography variant="h5">Task</Typography>
                </Box>
                <Card>
                  <Box
                    m={3}
                    sx={{ m: 3, display: "flex", alignItems: "center" }}
                  >
                    <b> Name: </b>
                    &emsp;
                    <TextField
                      sx={{ width: "97%" }}
                      value={taskName}
                      onChange={handleTaskNameValue}
                    />
                  </Box>
                </Card>
                {isValidPipeline(categories) ? (
                  <TabbedParameterForms
                    files={files}
                    pipelineId={currentPipeline.id}
                    invalidCategory={invalidCategory}
                    setInvalidCategory={setInvalidCategory}
                    setCategories={setCategories}
                    categories={categories}
                  />
                ) : (
                  <Card sx={{ my: 3, p: 2 }}>
                    <CardContent>
                      This pipeline has parameters not supported by the
                      dashboard.
                    </CardContent>
                  </Card>
                )}
              </>
            )}
          <JSONDrawer
            setCategories={setCategories}
            categories={categories}
            open={drawer}
            onClose={toggleDrawer}
          />
        </Box>
      </>
    </DashPageLayout>
  );
};

export default CreateTask;
