import { Box, Button, Drawer, TextField, Typography } from "@mui/material";
import { ChangeEvent, Dispatch, useEffect, useState } from "react";
import { Category } from "../../types/pipeline";
import { downloadFile } from "./storage/helpers";

const toApi = (json): any =>
  json
    .map((c) => c.params)
    .flat()
    .reduce((a, c) => ({ ...a, [c.name]: c.value }), {});

interface Props {
  open: boolean;
  onClose: () => void;
  categories: Category[];
  setCategories: Dispatch<Category[]>;
}

const JSONDrawer = ({
  open,
  onClose,
  categories,
  setCategories,
}: Props): JSX.Element => {
  const [paramsForApi, setParamsForApi] = useState<string>();
  const [error, setError] = useState(false);
  useEffect(() => {
    if (categories) {
      setParamsForApi(JSON.stringify(toApi(categories), null, 2));
    }
  }, [categories]);
  const handleChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setParamsForApi(value);
  };

  const handleSave = (): void => {
    try {
      const toConvert = JSON.parse(paramsForApi);
      const arr = Object.keys(toConvert).map((key) => ({
        name: key,
        value: toConvert[key],
      }));
      const updatedCats = categories.map((c) => ({
        name: c.name,
        params: c.params.map((p) => {
          const res = arr.find(({ name }) => p.name === name);
          return { ...p, value: res.value };
        }),
      }));
      setCategories(updatedCats);
      setError(false);
      onClose();
    } catch {
      setError(true);
    }
  };

  // const handleRevert = (): void => {
  //   setParamsForApi(JSON.stringify(toApi(categories), null, 2));
  //   setError(false);
  // };

  const handleDownload = (): void => {
    const blob = new Blob([paramsForApi], { type: "application/json" });
    downloadFile(blob, "parameters.json");
  };
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        zIndex: (theme): number => theme.zIndex.appBar + 201,
      }}
    >
      <Box sx={{ p: 3 }}>
        <br />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">Parameters in JSON Format</Typography>
          <Box>
            &nbsp;
            <Button variant="contained" onClick={handleSave}>
              Save
            </Button>
            &nbsp;
            <Button variant="contained" onClick={handleDownload}>
              Download
            </Button>
          </Box>
        </Box>
      </Box>
      <Box m={3}>
        <TextField
          multiline
          fullWidth
          sx={{
            minWidth: 700,
          }}
          inputProps={{
            style: {
              fontFamily:
                'Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace',
            },
          }}
          error={error}
          helperText={error ? "Invalid JSON or Schema" : ""}
          onChange={handleChange}
          value={paramsForApi}
        />
      </Box>
    </Drawer>
  );
};

export default JSONDrawer;
