import { Toaster } from "react-hot-toast";
import * as React from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useRoutes } from "react-router-dom";
import { createCustomTheme } from "./theme";
import useSettings from "./hooks/useSettings";
import SplashScreen from "./components/SplashScreen";
import useAuth from "./hooks/useAuth";
import routes from "./routes";
import SettingsButton from "./components/SettingsButton";

function App(): JSX.Element {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();
  const theme = createCustomTheme({
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Toaster position="top-center" />
      <SettingsButton />
      {auth.isInitialized ? content : <SplashScreen />}
    </ThemeProvider>
  );
}

export default App;
