import { Box, Card, CircularProgress, Typography } from "@mui/material";

interface Props {
  percentages?: { name: string; percent: number }[];
}

const UploadingCard = ({ percentages }: Props): JSX.Element => {
  let avg = null;
  if (percentages) {
    const percents = percentages.map((p) => p.percent);
    avg = Math.round(percents.reduce((a, b) => a + b, 0) / percents.length);
  }

  return (
    <Box sx={{ position: "absolute", bottom: 10, right: 10 }}>
      <Card
        sx={{
          boxShadow:
            "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)",
          p: 2,
          display: "flex",
        }}
      >
        {percentages ? (
          <>
            <CircularProgress variant="determinate" value={avg} size={20} />
            <Typography>&nbsp; {avg}% Progress...</Typography>
          </>
        ) : (
          <>
            <CircularProgress size={20} />
            <Typography>&nbsp; Compressing...</Typography>
          </>
        )}
      </Card>
      <Typography>
        * If progress is slow please consider using a{" "}
        <a href="https://docs.neuroscale.io/password?redirect=/docs/setup-storage">
          {" "}
          3rd party S3 client{" "}
        </a>
      </Typography>
    </Box>
  );
};
export default UploadingCard;
