import { Helmet } from "react-helmet-async";
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { format } from "date-fns";
import { ChangeEvent, useState } from "react";
import useSettings from "../../hooks/useSettings";
import { useGetBillingQuery } from "../../api/api";
import { PipelineBillingInfo } from "../../types/user";

const Usage = (): JSX.Element => {
  const filterByOptions = [
    { value: "all", label: "All" },
    { value: "batch", label: "Insights" },
    { value: "stream", label: "Streaming" },
  ];
  const { settings } = useSettings();
  const { data: billing, isLoading, error } = useGetBillingQuery();
  const [filterBy, setFilterBy] = useState("all");
  const handleFilterByChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setFilterBy(event.target.value);
  };

  const filterByType = (
    pipelines: PipelineBillingInfo[],
    type: string
  ): PipelineBillingInfo[] => {
    if (type === "all") {
      return pipelines;
    }
    return pipelines.filter((pipeline) => pipeline.pipeline_type === type);
  };

  const filteredPipelines = filterByType(billing?.pipelines ?? [], filterBy);
  if (isLoading) {
    return (
      <Skeleton
        variant="rectangular"
        sx={{ borderRadius: 3, mx: 3, my: 6, height: 700 }}
      />
    );
  }
  if (error) {
    return <Typography>service is currently unavailable</Typography>;
  }
  return (
    <>
      <Helmet>
        <title>Dashboard: Overview | NeuroScale</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 6,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid data-test="overview" container spacing={3}>
            <Grid
              alignItems="center"
              container
              justifyContent="space-between"
              spacing={3}
              item
              xs={12}
            >
              <Grid sx={{ width: "100%" }} item>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    mb: 2,
                  }}
                >
                  <Typography color="textPrimary" variant="h5">
                    Your Neuroscale Usage
                  </Typography>
                  <Button
                    variant="contained"
                    component={RouterLink}
                    to="/dashboard/billing"
                  >
                    Billing
                  </Button>
                </Box>
                <Typography color="textSecondary" variant="subtitle2">
                  An overview of the NCU (Neuroscale Compute Units) you have
                  consumed by running pipelines.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Grid container>
              <Card
                sx={{
                  p: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Box
                  sx={{
                    fontSize: "3rem",
                    fontWeight: "bolder",
                    color: (theme): string => theme.palette.primary.main,
                  }}
                >
                  {billing.freeUsage + billing.paidUsage}
                </Box>
                <Typography>NCUs Consumed</Typography>
                <Tooltip title='These are the total NCUs you have consumed so far this month. NCUs are first deducted from your "Prepaid NCU Balance", and, once that is exhausted, any further NCUs consumed are added to your "Unpaid NCU Balance'>
                  <Box>
                    <Typography sx={{ display: "flex" }}>
                      this month &nbsp;
                      <Box
                        sx={{
                          mt: 0.3,
                          height: 16,
                          width: 16,
                          borderRadius: "50%",
                          background: (theme): string =>
                            theme.palette.primary.main,
                          color: "white",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography mt={0.3} sx={{ fontSize: 12 }}>
                          ?
                        </Typography>
                      </Box>
                    </Typography>
                  </Box>
                </Tooltip>
              </Card>
              &emsp;
              <Card
                sx={{
                  p: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Box
                  sx={{
                    fontSize: "3rem",
                    fontWeight: "bolder",
                    color: (theme): string => theme.palette.primary.main,
                  }}
                >
                  {billing.creditsAvailable}
                </Box>
                <Typography>Prepaid</Typography>
                <Tooltip title="This is the balance of NCUs credited to your account that you pre-purchased (or received free of charge, or were credited under a service agreement.)">
                  <Box>
                    <Typography sx={{ display: "flex" }}>
                      This NCU Balance &nbsp;
                      <Box
                        sx={{
                          mt: 0.3,
                          height: 16,
                          width: 16,
                          borderRadius: "50%",
                          background: (theme): string =>
                            theme.palette.primary.main,
                          color: "white",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography mt={0.3} sx={{ fontSize: 12 }}>
                          ?
                        </Typography>
                      </Box>
                    </Typography>
                  </Box>
                </Tooltip>
              </Card>
              &emsp;
              <Card
                sx={{
                  width: 184,
                  p: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Box
                  sx={{
                    fontSize: "3rem",
                    fontWeight: "bolder",
                    color: (theme): string => theme.palette.primary.main,
                  }}
                >
                  {billing.paidUsage}
                </Box>
                <Typography>Unpaid</Typography>
                <Tooltip title="These are the NCUs you've consumed this month that you will be invoiced for at the end of the month.">
                  <Box>
                    <Typography sx={{ display: "flex" }}>
                      NCU Balance &nbsp;
                      <Box
                        sx={{
                          mt: 0.3,
                          height: 16,
                          width: 16,
                          borderRadius: "50%",
                          background: (theme): string =>
                            theme.palette.primary.main,
                          color: "white",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography mt={0.3} sx={{ fontSize: 12 }}>
                          ?
                        </Typography>
                      </Box>
                    </Typography>
                  </Box>
                </Tooltip>
              </Card>
            </Grid>
          </Box>
          <Box sx={{ mt: 3 }}>
            <Grid container>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-end",
                  my: 2,
                }}
              >
                <TextField
                  sx={{ background: "white", borderRadius: "0.8rem" }}
                  label="Filter By Type"
                  name="filter"
                  onChange={handleFilterByChange}
                  select
                  SelectProps={{ native: true }}
                  value={filterBy}
                  variant="outlined"
                >
                  {filterByOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Box>
              <Card sx={{ width: "100%", p: 3 }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <b>Pipeline Name</b>
                      </TableCell>
                      <TableCell align="right">
                        <b>NCUs Consumed</b>
                      </TableCell>
                      <TableCell align="right">
                        <b>Pipeline Type</b>
                      </TableCell>
                      <TableCell align="right">
                        <b>Run Date</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {billing.pipelines.length > 0 ? (
                      filteredPipelines.map((row) => (
                        <TableRow
                          key={Math.random().toString()}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.pipeline_name}
                          </TableCell>
                          <TableCell align="right">{row.units}</TableCell>
                          <TableCell align="right">
                            {row.pipeline_type === "batch"
                              ? "Insights"
                              : "Streaming"}
                          </TableCell>
                          <TableCell align="right">
                            {format(
                              new Date(row.begin_timestamp),
                              "MMM dd hh:mm a"
                            )}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <Box px={2} py={10}>
                        <Typography>
                          You have not run any pipelines. Please create one{" "}
                          <a href="/dashboard/tasks/create">here</a>.
                        </Typography>
                      </Box>
                    )}
                  </TableBody>
                </Table>
              </Card>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};
export default Usage;
