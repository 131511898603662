import { Card, CardContent, CardHeader } from "@mui/material";
import { Pipeline } from "../../../types/pipeline";

const PipelineOverview = ({
  name,
  description,
}: Pick<Pipeline, "name" | "description">): JSX.Element => {
  // TODO: handle case when description has no sentence
  const sentences = description.split(".");
  const firstSentence = `${sentences.shift()}.`;
  const remaining = sentences.join(".");
  return (
    <Card data-test="pipeline-overview">
      <CardHeader title={name} />
      <CardContent
        sx={{
          maxHeight: 300,
          overflow: "auto",
          mb: 2,
        }}
      >
        <b>{firstSentence}</b>
        <pre style={{ fontFamily: "sans-serif", whiteSpace: "pre-wrap" }}>
          {remaining}
        </pre>
      </CardContent>
    </Card>
  );
};

export default PipelineOverview;
