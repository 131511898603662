import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  Card,
  TextField,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { ChangeEvent, useState } from "react";
import toast from "react-hot-toast";
import { useCreateAuthorizationMutation } from "../../api/api";
import ScopeCard from "../../components/dashboard/authorization/ScopeCard";
import { zeros } from "../../api/transforms";
import type { Scopes } from "../../types/authorization";

const CreateTask = (): JSX.Element => {
  // hooks
  const nav = useNavigate();

  const [createAuthorization, { error: createError, isLoading: isCreating }] =
    useCreateAuthorizationMutation();

  const [scopes, setScopes] = useState<Scopes>(zeros);
  const [description, setDescription] = useState<string>("");

  // handlers
  const handleChangeDescription = (e: ChangeEvent<HTMLInputElement>): void => {
    setDescription(e.target.value);
  };

  if (createError) {
    toast.error("Something went wrong!");
  }
  const handleCreate = (): void => {
    if (!description) {
      toast.error("A description is required");
    } else {
      createAuthorization({
        description,
        scopes,
      });
      nav("/dashboard/authorizations");
    }
  };

  return (
    <>
      <Helmet>
        <title> Create An Authorization | NeuroScale</title>
      </Helmet>
      <Box
        data-test="authorization-overview"
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Create An Authorization
              </Typography>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <Button
                  data-test="authorization-create-button"
                  sx={{ m: 1 }}
                  variant="contained"
                  onClick={handleCreate}
                  disabled={isCreating}
                >
                  Create
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Grid container>
              <Box sx={{ width: "100%" }} mb={3}>
                <Card sx={{ p: 3, display: "flex", flexWrap: "wrap" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 3,
                      mr: 3,
                      flex: 1,
                    }}
                  >
                    <b> Description: </b>
                    &emsp;
                    <TextField
                      data-test="authorization-description-input"
                      multiline
                      sx={{ minWidth: 350, width: "100%" }}
                      name="description"
                      onChange={handleChangeDescription}
                      value={description}
                    />
                  </Box>
                </Card>
              </Box>
              <Box sx={{ width: "100%" }}>
                <Box mt={3} mb={2}>
                  <Typography variant="h6">Scopes</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-around",
                  }}
                >
                  {["pipeline", "instance", "task", "user"].map((resource) => (
                    <ScopeCard
                      key={resource}
                      resource={resource}
                      scopes={scopes}
                      setScopes={setScopes}
                    />
                  ))}
                </Box>
              </Box>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default CreateTask;
