export const formatDate = (epochDate: number): string => {
  const newDate = new Date(0);
  newDate.setUTCSeconds(epochDate);
  return newDate.toString().slice(4, 21);
};

export const getPeriod = (): string => {
  const hour = new Date().getHours();
  const morning = hour >= 4 && hour <= 11;
  const afternoon = hour >= 12 && hour <= 16;
  if (morning) {
    return "Morning";
  }
  if (afternoon) {
    return "Afternoon";
  }
  return "Evening";
};
