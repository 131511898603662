import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type ChangePassword from "../types/changePassword";
import User, { APIBilling, Billing, CardInfo } from "../types/user";
import { Pipeline } from "../types/pipeline";
import {
  APIAuthorization,
  Authorization,
  ResponseAuthorizations,
} from "../types/authorization";
import {
  scopeParser,
  transformAuthorization,
  transformInstance,
  transformInstanceView,
  transformPipeline,
} from "./transforms";
import { ResponseTask, Task } from "../types/tasks";
import {
  APIInstance,
  FieldCategory,
  Instance,
  ResponseInstance,
} from "../types/instances";
import { APIStorage, Storage } from "../types/storage";

export const URL_PART = process.env.REACT_APP_URL_PART;
if (!URL_PART) {
  throw new Error("URL_PART not in environment");
}
export const BASE_URL = `https://api-${URL_PART}.neuroscale.io/v1`;

class AuthApi {
  async register(user: User): Promise<void> {
    await fetch(`${BASE_URL}/users`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    });
  }

  async login({ username, password }: User): Promise<any> {
    try {
      const listResponse = await fetch(
        `${BASE_URL}/authorizations?fields=properties,access_token&format=v2`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Basic ${btoa(`${username}:${password}`)}`,
          },
        }
      );
      const { data: authorizations } = await listResponse.json();
      if (!authorizations) {
        throw new Error("Password or username is invalid.");
      }
      const authorization = authorizations.find((a) => a.properties.dashboard);

      if (authorization) {
        return authorization.access_token;
      }
      const getResponse = await fetch(`${BASE_URL}/authorizations`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Basic ${btoa(`${username}:${password}`)}`,
        },
        body: JSON.stringify({
          scopes: [
            "authorization:*",
            "instance:*",
            "pipeline:*",
            "task:*",
            "user:*",
          ],
          description: "Dashboard User Access",
          properties: {
            dashboard: true,
          },
        }),
      });
      const { access_token: accessToken } = await getResponse.json();
      return accessToken;
    } catch (e) {
      console.error({ loginError: e });
      throw new Error("Password or username is invalid.");
    }
  }

  async forgotPassword(email: string): Promise<void> {
    const response = await fetch(`${BASE_URL}/users/self/password/reset`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ email }),
    });
    if (response.status > 399) {
      throw new Error(response.statusText);
    }
  }

  async resetPassword(uid, token, new_password1, new_password2): Promise<void> {
    const response = await fetch(
      `${BASE_URL}/users/${uid}/password/reset/confirm`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ uid, token, new_password1, new_password2 }),
      }
    );
    if (response.status > 399) {
      const json = await response.json();
      if (json && json.Message.includes("Invalid")) {
        throw new Error("Your link is not longer valid");
      } else {
        throw new Error(response.statusText);
      }
    }
  }
}

export const changePassword = async ({
  currentPassword,
  newPassword1,
  newPassword2,
}: ChangePassword): Promise<boolean> => {
  const username = localStorage.getItem("username");
  const response = await fetch(`${BASE_URL}/users/self/password/change`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Basic ${btoa(`${username}:${currentPassword}`)}`,
    },
    body: JSON.stringify({
      new_password1: newPassword1,
      new_password2: newPassword2,
    }),
  });
  return response.status === 200;
};

interface ResponsePipeline {
  data: Pipeline[];
}

// @ts-ignore
export const api = createApi({
  refetchOnMountOrArgChange: true,
  tagTypes: ["Authorization"],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("accessToken");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    getUser: build.query<User, void>({
      query: () => "users/self",
      transformResponse(response: User): User {
        localStorage.setItem("is_staff", response.is_staff);
        localStorage.setItem("userId", response.id);
        localStorage.setItem("username", response.username);
        const restrictedPaths = response.properties.restricted_paths || [];
        localStorage.setItem(
          "restrictedPaths",
          JSON.stringify(restrictedPaths.map((v) => v.replace("*", "")))
        );
        return {
          avatar: `/static/avatars/${response.first_name[0].toUpperCase()}.svg`,
          ...response,
        };
      },
    }),
    getPipelineParams: build.query<unknown, unknown>({
      query: (pipelineId: string) => {
        if (pipelineId) {
          return `pipelines/${pipelineId}`;
        }
        return undefined;
      },
      transformResponse: (p: Pipeline): Parameters<any> => p.parameters,
    }),
    getPipeline: build.query<Pipeline, unknown>({
      query: (pipelineId: string) => {
        if (pipelineId) {
          return `pipelines/${pipelineId}`;
        }
        return undefined;
      },
      transformResponse: (p: Pipeline): Pipeline => transformPipeline.out(p),
    }),
    getPipelines: build.query<Pipeline[], void>({
      query: () =>
        "pipelines?fields=name,description,created_at,updated_at,type,id,parameters",
      transformResponse(response: ResponsePipeline): Pipeline[] {
        const { data } = response;
        return data
          .map((p): Pipeline => transformPipeline.out(p))
          .sort((a, b) => a.name.localeCompare(b.name));
      },
    }),
    getAuthorizations: build.query<Authorization[], void>({
      query: () =>
        "authorizations?fields=id,access_token,description,created_at,updated_at&format=v2",
      transformResponse(response: ResponseAuthorizations): Authorization[] {
        return response.data;
      },
      providesTags: ["Authorization"],
    }),
    getAuthorization: build.query<Authorization, unknown>({
      query: (authorizationId: string) => `authorizations/${authorizationId}`,
      transformResponse: (a: APIAuthorization): Authorization =>
        transformAuthorization.out(a),
      providesTags: ["Authorization"],
    }),
    createAuthorization: build.mutation<
      APIAuthorization,
      Partial<Authorization>
    >({
      query(data) {
        const { id, scopes, ...body } = data;
        const parsedScopes = scopeParser.in(scopes);
        return {
          url: `authorizations`,
          method: "POST",
          body: {
            ...body,
            scopes: parsedScopes,
          },
        };
      },
      invalidatesTags: ["Authorization"],
    }),
    patchUser: build.mutation<User, Partial<User>>({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `users/${id}`,
          method: "PATCH",
          body,
        };
      },
    }),
    updateAuthorization: build.mutation<
      APIAuthorization,
      Partial<Authorization>
    >({
      query(data) {
        const { id, scopes, ...body } = data;
        const parsedScopes = scopeParser.in(scopes);
        return {
          url: `authorizations/${id}`,
          method: "PATCH",
          body: {
            ...body,
            scopes: parsedScopes,
          },
        };
      },
      invalidatesTags: ["Authorization"],
    }),
    deleteAuthorization: build.mutation<boolean, string>({
      query(id) {
        return {
          url: `authorizations/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Authorization"],
    }),
    createTask: build.mutation<Task, Partial<Task>>({
      query(body) {
        return {
          url: `tasks`,
          method: "POST",
          body,
        };
      },
    }),
    cancelTask: build.mutation<boolean, string>({
      query(id) {
        return {
          url: `tasks/${id}/cancel`,
          method: "PUT",
        };
      },
    }),
    getVersion: build.query<{ version: string }, void>({
      query: () => `version`,
    }),
    getTask: build.query<Task, string>({
      query: (taskId) => `tasks/${taskId}`,
      transformResponse(task: Task): Task {
        return task;
      },
    }),
    getTasks: build.query<ResponseTask, string | void>({
      query: (nextCursor) =>
        `tasks?with_total=1&limit=50&next_cursor=${nextCursor}`,
    }),
    deleteTask: build.mutation<boolean, string>({
      query(id) {
        return {
          url: `tasks/${id}`,
          method: "DELETE",
        };
      },
    }),
    getInstances: build.query<ResponseInstance, string | void>({
      query: (nextCursor) =>
        `instances?with_total=1&limit=50&next_cursor=${nextCursor}`,
      transformResponse(response: ResponseInstance): ResponseInstance {
        const { data, ...rest } = response;
        const updatedData = data.map((i): Instance => transformInstance.out(i));
        return { data: updatedData, ...rest } as any;
      },
    }),
    deleteInstance: build.mutation<boolean, string>({
      query(id) {
        return {
          url: `instances/${id}`,
          method: "DELETE",
        };
      },
    }),
    getInstance: build.query<FieldCategory[], string>({
      query: (instanceId) => `instances/${instanceId}`,
      transformResponse(i: APIInstance): FieldCategory[] {
        return transformInstanceView(i);
      },
    }),
    getInstanceDebugLog: build.query<string, string>({
      query: (instanceId) => `instances/${instanceId}/debug`,
      transformResponse(i: any): string {
        return JSON.stringify(i.config_data, null, 2);
      },
    }),
    createInstance: build.mutation<Instance, Partial<Instance>>({
      query(body) {
        return {
          url: `instances`,
          method: "POST",
          body,
        };
      },
    }),

    getStorage: build.query<Storage, void>({
      query: () => `users/self/storage`,
      transformResponse(s: APIStorage): Storage {
        return {
          region: s.region,
          bucket: s.container_name,
          accessKeyId: s.account_name,
          secretAccessKey: s.credentials,
        };
      },
    }),
    getDocsUrl: build.query<{ docs_url: string }, void>({
      query() {
        return {
          url: `actions/exchange-token?type=docs`,
          method: "GET",
        };
      },
    }),
    getPresignedUrl: build.query<{ readme_url: string }, string>({
      query(key) {
        return {
          url: `actions/exchange-token?type=presigned_s3_url&object=${key}`,
          method: "GET",
        };
      },
    }),
    getBilling: build.query<Billing, void>({
      query: () => "users/self/billing/dashboard",
      transformResponse(b: APIBilling): Billing {
        return {
          freeUsage: b.free_usage,
          paidUsage: b.paid_usage,
          pipelines: b.pipelines.sort((a, c) =>
            c.begin_timestamp.localeCompare(a.begin_timestamp)
          ),
          creditsAvailable: b.credits_available,
        };
      },
    }),
    getCard: build.query<CardInfo, void>({
      query: () => "users/self/billing/card",
    }),
  }),
});

export const getInvoices = async (username, password): Promise<any> => {
  const invoiceResponse = await fetch(
    `${BASE_URL}/users/self/billing/invoices`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Basic ${btoa(`${username}:${password}`)}`,
      },
    }
  );
  if (invoiceResponse.status < 300) {
    return invoiceResponse.json();
  }
  return console.error("No Invoices found or bad username and password");
};

export const getRunningTaskCounts = async (): Promise<any> => {
  const token = localStorage.getItem("accessToken");
  const resp = await fetch(`${BASE_URL}/tasks?fields=state`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const res = await resp.json();
  if (res.Message === "User is not authorized to access this resource") {
    localStorage.removeItem("accessToken");
    // @ts-ignore
    window.location = "/";
  }
  const runnings = res.data.filter(
    (r) =>
      r.state === "running" ||
      r.state === "runnable" ||
      r.state === "starting" ||
      r.state === "submitted" ||
      r.state === "pending"
  );
  return runnings.length;
};

export const getPipelineCounts = async (): Promise<any> => {
  const token = localStorage.getItem("accessToken");
  const resp = await fetch(`${BASE_URL}/pipelines?fields=type`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (resp.status > 399) {
    localStorage.removeItem("accessToken");
    // @ts-ignore
    window.location = "/";
    return null;
  }
  const { data } = await resp.json();

  return {
    tasks: data.filter((d) => d.type === "batch").length,
    instances: data.filter((d) => d.type === "stream").length,
  };
};

export const getInstanceCounts = (cursor = ""): any =>
  Promise.all(
    ["instances"].map(async (resource): Promise<any> => {
      const token = localStorage.getItem("accessToken");
      const resp = await fetch(
        `${BASE_URL}/${resource}?fields=id&next_cursor=${cursor}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { count } = await resp.json();
      return count;
    })
  );

export const {
  useGetUserQuery,
  usePatchUserMutation,
  useGetPipelinesQuery,
  useGetPipelineQuery,
  useGetPipelineParamsQuery,
  useGetAuthorizationsQuery,
  useDeleteAuthorizationMutation,
  useGetAuthorizationQuery,
  useUpdateAuthorizationMutation,
  useCreateAuthorizationMutation,
  useCreateTaskMutation,
  useGetTasksQuery,
  useGetTaskQuery,
  useGetStorageQuery,
  useDeleteTaskMutation,
  useGetInstancesQuery,
  useGetInstanceQuery,
  useGetInstanceDebugLogQuery,
  useDeleteInstanceMutation,
  useCreateInstanceMutation,
  useGetDocsUrlQuery,
  useGetPresignedUrlQuery,
  useGetVersionQuery,
  useGetBillingQuery,
  useGetCardQuery,
  useCancelTaskMutation,
} = api;

export const authApi = new AuthApi();
