import type { ChangeEvent, Dispatch } from "react";
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Skeleton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { toast } from "react-hot-toast";
import { Category } from "../../../types/pipeline";
import { TabPanel } from "../pipeline/TabbedParameters";
import { FolderOpenIcon, ResetIcon } from "../../../icons";
import { useGetPipelinesQuery, useGetStorageQuery } from "../../../api/api";
import FileBrowser from "../storage/FileBrowser";
import InputByDataType from "./InputByDataType";

const TabbedParameterForms = ({
  pipelineId,
  categories,
  setCategories,
  invalidCategory,
  setInvalidCategory,
  files,
}: {
  pipelineId?: string;
  categories: Category[];
  setCategories: Dispatch<any>;
  invalidCategory?: string;
  setInvalidCategory?: Dispatch<string>;
  files?: string;
}): JSX.Element => {
  const [currentTab, setCurrentTab] = useState<string>(categories[0].name);
  const [storageDialog, setStorageDialog] = useState(false);
  const [selectedPath, setSelectedPath] = useState("");
  const { data: pipelines } = useGetPipelinesQuery();
  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
  };
  const refetchOneItem = (paramName): void => {
    if (paramName === "input_path") {
      setCategories((draft) => {
        draft
          .find((c) => c.name === currentTab)
          .params.find((p) => p.name === paramName).value = files;
      });
    } else {
      try {
        if (pipelineId) {
          const { value: defaultValue } = pipelines.find(
            (p) => p.id === pipelineId
          ).parameters[paramName];

          setCategories((draft) => {
            draft
              .find((c) => c.name === currentTab)
              .params.find((p) => p.name === paramName).value = defaultValue;
          });
          setInvalidCategory("");
        }
      } catch (e) {
        toast.error(e.message);
      }
    }
  };
  const openStorage = (): void => {
    setStorageDialog(true);
  };
  const storageDialogClose = (): void => {
    setStorageDialog(false);
    setSelectedPath("");
  };
  const handleOnSelect = (): void => {
    setCategories((draft) => {
      draft
        .find((c) => c.name === currentTab)
        .params.find((p) => p.name === "input_path").value = selectedPath;
    });
    setStorageDialog(false);
  };
  const { data: creds, isLoading: isCredsLoading } = useGetStorageQuery();
  useEffect(() => {
    setCurrentTab(categories[0].name);
  }, [categories[0].name]);
  return (
    <Grid>
      <Dialog
        maxWidth="lg"
        sx={{ height: 700 }}
        onClose={storageDialogClose}
        open={storageDialog}
      >
        <DialogContent sx={{ height: 500 }}>
          {isCredsLoading ? (
            <Skeleton
              variant="rectangular"
              width="100%"
              height="100%"
              sx={{ borderRadius: 2 }}
            />
          ) : (
            //  @ts-ignore
            <FileBrowser setSelectedPath={setSelectedPath} {...creds} />
          )}
        </DialogContent>
        <Typography
          sx={{ mx: 3, mt: 1, textAlign: "left", wordBreak: "break-all" }}
        >
          Selected Paths: <br />
          <code style={{ background: "rgba(0,0,0,0.2)", fontSize: 12 }}>
            {selectedPath}
          </code>
        </Typography>
        <DialogActions sx={{ mr: 2 }}>
          <Button onClick={handleOnSelect} disabled={!selectedPath}>
            Select
          </Button>
        </DialogActions>
      </Dialog>
      <Box mt={3} mb={2}>
        <Typography variant="h6">Parameters</Typography>
      </Box>
      <Tabs
        onChange={handleTabsChange}
        scrollButtons="auto"
        textColor="primary"
        value={currentTab}
        variant="scrollable"
      >
        {categories &&
          categories.map((category) => (
            <Tab
              style={
                invalidCategory === category.name ? { color: "#f44336" } : {}
              }
              key={category.name}
              label={category.name}
              value={category.name}
            />
          ))}
      </Tabs>
      {categories.map((cat) => (
        <TabPanel key={cat.name} value={cat.name} index={currentTab}>
          {cat.params &&
            cat.params.map((param, i) => (
              <Box key={param.name} mb={3}>
                <Card>
                  <Box m={2}>
                    <Box mb={2}>
                      <Typography variant="h6">{param.verbose_name}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }} mb={3}>
                      <b>Value: </b>
                      &emsp;
                      <InputByDataType
                        invalidCategory={invalidCategory}
                        setInvalidCategory={setInvalidCategory}
                        setCategories={setCategories}
                        param={param}
                      />
                      &emsp;
                      {param.is_filename && (
                        <Tooltip title="Open file storage">
                          <IconButton onClick={openStorage}>
                            <FolderOpenIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {currentTab !== "Uncategorized" && (
                        <Tooltip title="Reset to original value">
                          <IconButton
                            data-test={`reset-button-${i}`}
                            onClick={(): void => refetchOneItem(param.name)}
                          >
                            <ResetIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                    <pre
                      style={{
                        fontFamily: "sans-serif",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {param.description}
                    </pre>
                  </Box>
                </Card>
              </Box>
            ))}
        </TabPanel>
      ))}
    </Grid>
  );
};
export default TabbedParameterForms;
