import { lazy, Suspense } from "react";
import type { PartialRouteObject } from "react-router";
import GuestGuard from "./components/authentication/GuestGuard";
import AuthGuard from "./components/authentication/AuthGuard";
import LoadingScreen from "./components/LoadingScreen";
import DashboardLayout from "./components/dashboard/DashboardLayout";
import CreateAuthorization from "./pages/dashboard/CreateAuthorization";
import AuthorizationList from "./pages/dashboard/AuthorizationList";
import AuthorizationDetails from "./pages/dashboard/AuthorizationDetails";
import CreateTask from "./pages/dashboard/CreateTask";
import TaskList from "./pages/dashboard/TaskList";
import TaskDetails from "./pages/dashboard/TaskDetails";
import InstanceList from "./pages/dashboard/InstanceList";
import InstanceDetails from "./pages/dashboard/InstanceDetails";
import Usage from "./pages/dashboard/Usage";
import Billing from "./pages/dashboard/Billing";
import CreateInstance from "./pages/dashboard/CreateInstance";
import Logs from "./pages/dashboard/Logs";

const Loadable =
  (Component) =>
  (props): JSX.Element =>
    (
      <Suspense fallback={<LoadingScreen />}>
        <Component {...props} />
      </Suspense>
    );

const Login = Loadable(lazy(() => import("./pages/authentication/Login")));
const ForgotPassword = Loadable(
  lazy(() => import("./pages/authentication/ForgotPassword"))
);
const PasswordReset = Loadable(
  lazy(() => import("./pages/authentication/PasswordReset"))
);
const EmailConfirm = Loadable(
  lazy(() => import("./pages/authentication/EmailConfirm"))
);
const NotFound = Loadable(lazy(() => import("./pages/NotFound")));
const Sandbox = Loadable(lazy(() => import("./pages/Sandbox")));
const Storage = Loadable(lazy(() => import("./pages/dashboard/Storage")));
const Account = Loadable(lazy(() => import("./pages/dashboard/Account")));
const Overview = Loadable(lazy(() => import("./pages/dashboard/Overview")));
const PipelineList = Loadable(
  lazy(() => import("./pages/dashboard/PipelineList"))
);
const PipelineDetails = Loadable(
  lazy(() => import("./pages/dashboard/PipelineDetails"))
);

// Browse pages
const routes: PartialRouteObject[] = [
  {
    path: "/",
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    ),
  },
  //  {
  //    path: "register",
  //    element: (
  //      <GuestGuard>
  //        <Register />
  //      </GuestGuard>
  //    ),
  //  },
  {
    path: "users/:userId/email/confirm/:token",
    element: (
      <GuestGuard>
        <EmailConfirm />
      </GuestGuard>
    ),
  },
  {
    path: "/dashboard/password/reset",
    element: (
      <GuestGuard>
        <ForgotPassword />
      </GuestGuard>
    ),
  },
  {
    path: "/dashboard/password/reset/confirm/usr_*",
    element: (
      <GuestGuard>
        <PasswordReset />
      </GuestGuard>
    ),
  },
  {
    path: "/dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <Overview />,
      },
      {
        path: "sandbox",
        element: <Sandbox />,
      },
      {
        path: "billing",
        element: <Billing />,
      },
      {
        path: "usage",
        element: <Usage />,
      },
      {
        path: "account",
        element: <Account />,
      },
      {
        path: "storage",
        element: <Storage />,
      },
      {
        path: "authorizations",
        children: [
          {
            path: "/",
            element: <AuthorizationList />,
          },
          {
            path: ":authorizationId",
            element: <AuthorizationDetails />,
          },
          {
            path: "create",
            element: <CreateAuthorization />,
          },
        ],
      },
      {
        path: "tasks",
        children: [
          {
            path: "/",
            element: <TaskList />,
          },
          {
            path: ":taskId",
            children: [
              { path: "/", element: <TaskDetails /> },
              { path: "logs", element: <Logs resourceType="Task" /> },
            ],
          },
          {
            path: "create",
            children: [
              { path: "/", element: <CreateTask /> },
              { path: ":pipelineId", element: <CreateTask /> },
            ],
          },
          {
            path: "pipelines",
            children: [
              {
                path: "/",
                element: <PipelineList type="batch" />,
              },
              {
                path: ":pipelineId",
                element: <PipelineDetails path="task" />,
              },
            ],
          },
        ],
      },
      {
        path: "instances",
        children: [
          {
            path: "/",
            element: <InstanceList />,
          },
          {
            path: ":instanceId",
            element: <InstanceDetails />,
          },
          {
            path: "create",
            children: [
              { path: "/", element: <CreateInstance /> },
              { path: ":pipelineId", element: <CreateInstance /> },
            ],
          },
          {
            path: "pipelines",
            children: [
              {
                path: "/",
                element: <PipelineList type="stream" />,
              },
              {
                path: ":pipelineId",
                element: <PipelineDetails path="instance" />,
              },
            ],
          },
        ],
      },
    ],
  },

  {
    path: "*",
    element: <NotFound />,
  },
];

// @ts-ignore
export default routes;
