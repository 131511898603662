import { createSvgIcon } from "@mui/material";

const Reset = createSvgIcon(
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    height="512"
    viewBox="0 0 512 512"
    width="512"
  >
    <path d="m21 12a9 9 0 1 1 -3.84-7.36l-.11-.32a1 1 0 0 1 1.95-.64l1 3a1 1 0 0 1 -.14.9 1 1 0 0 1 -.86.42h-3a1 1 0 0 1 -1-1 1 1 0 0 1 .71-.94 7 7 0 1 0 3.29 5.94 1 1 0 0 1 2 0z" />
  </svg>,
  "Reset"
);

export default Reset;
