import { createSvgIcon } from "@mui/material";

const Check = createSvgIcon(
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
  >
    <path d="M362.667 42.667h-89.28C264.64 17.92 241.173 0 213.333 0s-51.307 17.92-60.053 42.667H64c-23.573 0-42.667 19.093-42.667 42.667V384c0 23.573 19.093 42.667 42.667 42.667h298.667c23.573 0 42.667-19.093 42.667-42.667V85.333c-.001-23.573-19.094-42.666-42.667-42.666zm-149.334 0c11.733 0 21.333 9.493 21.333 21.333 0 11.84-9.6 21.333-21.333 21.333S192 75.84 192 64c0-11.84 9.6-21.333 21.333-21.333zm-42.666 298.666L85.333 256l30.187-30.187 55.147 55.147 140.48-140.48 30.187 30.187-170.667 170.666z" />
  </svg>,
  "Check"
);

export default Check;
