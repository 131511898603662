import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Button,
  Container,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";

import { useImmer } from "use-immer";
import useSettings from "../../hooks/useSettings";
import { useDeleteInstanceMutation, useGetInstancesQuery } from "../../api/api";
import ResourceList from "../../components/dashboard/resourceList/ResourceList";

const InstanceList = (): JSX.Element => {
  const [page, setPage] = useState(0);
  const { settings } = useSettings();
  const [nextCursor, setNextCursor] = useImmer("");
  const [instances, setInstances] = useImmer([]);
  const [currentInstances, setCurrentInstance] = useState([]);
  const {
    data: instancesResponse,
    refetch,
    isLoading,
    error,
  } = useGetInstancesQuery(nextCursor);

  const [deleteInstance, { isLoading: isDeleting }] =
    useDeleteInstanceMutation();
  useEffect(() => {
    if (instancesResponse && !instances.length) {
      setNextCursor(instancesResponse.next_cursor);
      setCurrentInstance(instancesResponse.data);
      setInstances((draft) => {
        draft[0] = instancesResponse.data;
      });
    }
  }, [isLoading]);

  const handlePageChange = async (e: any, newPage: number): Promise<void> => {
    const isNext = e.currentTarget.getAttribute("title").includes("next");
    if (isNext) {
      const instanceAlreadyExists = instances[newPage];
      if (!instanceAlreadyExists) {
        await refetch();
        setNextCursor(instancesResponse.next_cursor);
        setCurrentInstance(instancesResponse.data);
        setInstances((draft) => {
          draft[newPage] = instancesResponse.data;
        });
      } else {
        setCurrentInstance(instances[newPage]);
      }
    } else {
      setCurrentInstance(instances[newPage]);
    }
    setPage(newPage);
  };
  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          height: "80vh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h4>This resource is currently unavailable. Please try again later.</h4>
      </Box>
    );
  }
  return (
    <>
      <Helmet>
        <title>Streaming Instance List | NeuroScale</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 8,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Streaming Instance List
              </Typography>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <Button
                  component={RouterLink}
                  sx={{ m: 1 }}
                  variant="contained"
                  to="/dashboard/instances/create"
                >
                  Create Instance
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {isLoading || isDeleting ? (
              <Skeleton
                sx={{
                  width: "100%",
                  height: 600,
                  marginTop: -15,
                }}
              />
            ) : instancesResponse.data.length === 0 ? (
              <Box
                data-test="no-instances-message"
                sx={{
                  height: "20vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p>
                  No instances are available yet. Please{" "}
                  <RouterLink to="/dashboard/instances/create">
                    create a new instance
                  </RouterLink>{" "}
                  and come back.
                </p>
              </Box>
            ) : (
              <div data-test="instance-list">
                <ResourceList
                  resourceType="instances"
                  headers={[
                    "id",
                    "pipeline",
                    "description",
                    "state",
                    "receiving",
                    "publishing",
                    "calibrating",
                    "created_at",
                  ]}
                  bulkEdit
                  resources={currentInstances}
                  deleteResource={deleteInstance}
                  total_count={instancesResponse.total_count}
                  page={page}
                  handlePageChange={handlePageChange}
                />
              </div>
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default InstanceList;
