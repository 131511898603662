import { Dispatch } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Box, Button } from "@mui/material";
import { toast } from "react-hot-toast";
import { BASE_URL, URL_PART } from "../../../api/api";

const CheckoutForm = ({
  mode,
  setMode,
}: {
  mode: string;
  setMode: Dispatch<string>;
}): JSX.Element => {
  const stripe = useStripe();
  const elements = useElements();
  const handleSubmit = async (event): Promise<void> => {
    event.preventDefault();
    const cardElement = elements.getElement("card");
    stripe.createToken(cardElement).then((result) => {
      if (result.error) {
        toast.error(result.error.message);
      } else {
        const token = localStorage.getItem("accessToken");
        fetch(`${BASE_URL}/users/self/billing/card/${mode.toLowerCase()}`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token: result.token.id }),
        }).then((addCardRes) => {
          if (addCardRes.status < 300) {
            console.info({ addCardRes });
            toast.success("Card successfully saved!");
            setMode("View");
          } else {
            console.error({ addCardRes });
            toast.error("Currently unable to add card");
          }
        });
      }
    });
  };

  return (
    <Box m={3}>
      <form onSubmit={handleSubmit}>
        <CardElement />
        <Button
          style={{ marginTop: 30 }}
          variant="contained"
          type="submit"
          disabled={!stripe || !elements}
        >
          {mode}
        </Button>
      </form>
    </Box>
  );
};

const stripePromise = loadStripe(
  URL_PART === "experimental"
    ? "pk_test_ZvRUzvU4xUUgbi3YKBLukIEq"
    : "pk_live_x11W8zREszIBgc5A5ku7xbt1"
);

const BillingCardForm = ({
  mode,
  setMode,
}: {
  mode: string;
  setMode: Dispatch<string>;
}): JSX.Element => (
  <Elements stripe={stripePromise}>
    <CheckoutForm mode={mode} setMode={setMode} />
  </Elements>
);

export default BillingCardForm;
