import { useRef, useState } from "react";
import { subHours } from "date-fns";
import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import BellIcon from "../../icons/Bell";
import { FolderOpenIcon } from "../../icons";

interface Notification {
  id: string;
  title: string;
  description?: string;
  type: string;
  createdAt: number;
  url: string;
}

const now = new Date();

const notifications: Notification[] = [
  {
    id: "5e8883f1b51cc1956a5a1ec0",
    createdAt: subHours(now, 2).getTime(),
    title: "Results for Task: Good ERP",
    url: "/dashboard/storage",
    type: "new_message",
    description: "some task description",
  },
];

const NotificationsPopover = (): JSX.Element => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [notificationCount] = useState(0);
  // todo: make sure you test this against mozilla security in prod
  // useEffect(() => {
  //   const ws = new WebSocket(BASE_WSS);
  //   ws.onopen = (): void => console.info("ws opened");
  //   ws.onclose = (): void => console.info("ws closed");
  //
  //   ws.onmessage = (): void => {
  //     setNotificationCount(notificationCount + 1);
  //   };
  //
  //   return (): void => {
  //     ws.close();
  //   };
  // }, []);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton color="inherit" ref={anchorRef} onClick={handleOpen}>
          {notificationCount === 0 ? (
            <BellIcon fontSize="small" />
          ) : (
            <Badge
              data-testid="notification-dot"
              color="error"
              badgeContent={notificationCount}
            >
              <BellIcon fontSize="small" />
            </Badge>
          )}
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 320 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="h6">
            Notifications
          </Typography>
        </Box>
        {notificationCount === 0 ? (
          <Box sx={{ p: 2 }}>
            <Typography color="textPrimary" variant="subtitle2">
              There are no notifications
            </Typography>
          </Box>
        ) : (
          <>
            <List disablePadding>
              {notifications.map((notification) => (
                <ListItem divider key={notification.id}>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        backgroundColor: "primary.main",
                        color: "primary.contrastText",
                      }}
                    >
                      <FolderOpenIcon fontSize="small" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Link
                        color="textPrimary"
                        sx={{ cursor: "pointer" }}
                        underline="none"
                        variant="subtitle2"
                        href={notification.url}
                      >
                        {notification.title}
                      </Link>
                    }
                    secondary={notification.description}
                  />
                </ListItem>
              ))}
            </List>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                p: 1,
              }}
            >
              <Button color="primary" size="small" variant="text">
                Mark all as read
              </Button>
            </Box>
          </>
        )}
      </Popover>
    </>
  );
};

export default NotificationsPopover;
