import { Box, Checkbox } from "@mui/material";
import { ChangeEvent } from "react";
import DeleteConfirmation from "../../DeleteConfirmation";
import { camelToSentence } from "../../../utils/stringTransform";

interface BulkEditProps {
  resourceType: "task" | "pipeline" | "instances" | "tasks" | "authorizations";
  selectedResources: string[];
  selectedAllResources: boolean;
  selectedSomeResources: boolean;
  deleteResource: (id: string) => void;
  handleSelectAllResources: (e: ChangeEvent<HTMLInputElement>) => void;
}

const BulkEdit = (props: BulkEditProps): JSX.Element => {
  const {
    selectedResources,
    selectedAllResources,
    selectedSomeResources,
    handleSelectAllResources,
    deleteResource,
    resourceType,
  } = props;
  return (
    <Box
      sx={{ position: "relative", zIndex: 2, width: "100%", top: 12, left: -2 }}
    >
      <Box
        sx={{
          backgroundColor: "background.paper",
          position: "absolute",
          width: "100%",
        }}
      >
        <Checkbox
          checked={selectedAllResources}
          color="primary"
          indeterminate={selectedSomeResources}
          onChange={handleSelectAllResources}
        />
        <DeleteConfirmation
          title={`Delete ${camelToSentence(resourceType)}(s)?`}
          deleteResource={deleteResource}
          selectedResources={selectedResources}
        />
      </Box>
    </Box>
  );
};
export default BulkEdit;
