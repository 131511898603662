import { useState, useEffect } from "react";
import { useImmer } from "use-immer";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Button,
  Container,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import useSettings from "../../hooks/useSettings";
import { useDeleteTaskMutation, useGetTasksQuery } from "../../api/api";
import ResourceList from "../../components/dashboard/resourceList/ResourceList";

const TaskList = (): JSX.Element => {
  const { settings } = useSettings();

  const [deleteTask, { isLoading: isDeleting }] = useDeleteTaskMutation();
  const [page, setPage] = useState(0);
  const [currentTasks, setCurrentTasks] = useState([]);
  const [tasks, setTasks] = useImmer([]);
  const [nextCursor, setNextCursor] = useImmer("");
  const {
    data: taskApiResponse,
    refetch,
    isLoading,
  } = useGetTasksQuery(nextCursor);

  useEffect(() => {
    if (taskApiResponse && !tasks.length) {
      setNextCursor(taskApiResponse.next_cursor);
      setCurrentTasks(taskApiResponse.data);
      setTasks((draft) => {
        draft[0] = taskApiResponse.data;
      });
    }
  }, [isLoading]);

  const handlePageChange = async (e: any, newPage: number): Promise<void> => {
    const isNext = e.currentTarget.getAttribute("title").includes("next");
    if (isNext) {
      const taskAlreadyExists = tasks[newPage];
      if (!taskAlreadyExists) {
        await refetch();
        setNextCursor(taskApiResponse.next_cursor);
        setCurrentTasks(taskApiResponse.data);
        setTasks((draft) => {
          draft[newPage] = taskApiResponse.data;
        });
      } else {
        setCurrentTasks(tasks[newPage]);
      }
    } else {
      setCurrentTasks(tasks[newPage]);
    }
    setPage(newPage);
  };

  return (
    <>
      <Helmet>
        <title>Insights Task List | NeuroScale</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 8,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Insights Task List
              </Typography>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <Button
                  component={RouterLink}
                  sx={{ m: 1 }}
                  variant="contained"
                  to="/dashboard/tasks/create"
                >
                  Create Task
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {isLoading || isDeleting ? (
              <Skeleton
                sx={{
                  width: "100%",
                  height: 600,
                  marginTop: -15,
                }}
              />
            ) : !taskApiResponse || !taskApiResponse.data ? (
              <Box
                data-test="no-tasks-message"
                sx={{
                  height: "20vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p>
                  No tasks are available yet. Please{" "}
                  <RouterLink to="/dashboard/tasks/create">
                    create a new task
                  </RouterLink>{" "}
                  and come back.
                </p>
              </Box>
            ) : (
              <ResourceList
                resourceType="tasks"
                dataTest="task-list"
                headers={["id", "name", "pipeline", "state", "created_at"]}
                bulkEdit
                resources={currentTasks}
                deleteResource={deleteTask}
                total_count={taskApiResponse.total_count}
                page={page}
                handlePageChange={handlePageChange}
              />
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default TaskList;
