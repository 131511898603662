import { IconButton } from "@mui/material";
import { RefreshIcon } from "../../../icons";

const RefetchButton = ({
  handleRefetch,
}: {
  handleRefetch: () => void;
}): JSX.Element => (
  <IconButton>
    <RefreshIcon onClick={handleRefetch} />
  </IconButton>
);

export default RefetchButton;
