import { alpha, Avatar, Box, Button, Dialog, Typography } from "@mui/material";
import { useState } from "react";
import WarningIcon from "@mui/icons-material/WarningOutlined";
import { useNavigate } from "react-router-dom";

interface DeleteConfirmationProps {
  title: string;
  confirmation?: string;
  deleteResource: (id: string) => any;
  selectedResources: string[];
  variant?: "text" | "outlined" | "contained";
  "data-test"?: string;
  taskId?: string;
  label?: string;
}

const DeleteConfirmation = (props: DeleteConfirmationProps): JSX.Element => {
  const nav = useNavigate();
  const {
    title,
    deleteResource,
    selectedResources,
    variant,
    label,
    confirmation,
    taskId,
    "data-test": dataTest,
  } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const taskIdFromLocalStorage = localStorage.getItem("taskId");
  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleDestroy = (): void => {
    // TODO: make sure selected resources is an array of ids
    selectedResources.forEach(async (resourceId) => {
      const res = await deleteResource(resourceId);
      if (res && res.error) {
        setError(true);
        setTimeout(() => {
          setOpen(false);
          setError(false);
        }, 2000);
      } else {
        setSuccess(true);
        localStorage.setItem("taskId", taskId);
        setTimeout(() => {
          setOpen(false);
          if (label === "Cancel Task") {
            nav("/dashboard/tasks");
          }
        }, 2000);
      }
    });
  };
  // @ts-ignore
  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {error ? (
          <Box p={3}>Error cancelling task. Please try again.</Box>
        ) : success ? (
          <Box p={3}>{confirmation}</Box>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                pb: 2,
                pt: 3,
                px: 3,
              }}
            >
              <Avatar
                sx={{
                  backgroundColor: (theme): string =>
                    alpha(theme.palette.error.main, 0.08),
                  color: "error.main",
                  mr: 2,
                }}
              >
                <WarningIcon />
              </Avatar>
              <Box>
                <Typography color="textPrimary" variant="h5">
                  {title}
                </Typography>
                <Typography mt={1}>
                  Large deletions may take a while to complete. Please refresh
                  to confirm.
                </Typography>
                <Box
                  sx={{
                    my: 2,
                    mr: 7,
                    maxWidth: 300,
                    wordWrap: "break-word",
                  }}
                >
                  {String(selectedResources)}
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                px: 3,
                py: 1.5,
              }}
            >
              <Button
                onClick={handleClose}
                color="primary"
                sx={{ mr: 2 }}
                variant="contained"
              >
                No
              </Button>
              <Button
                data-test="delete-confirm"
                onClick={handleDestroy}
                sx={{
                  backgroundColor: "error.main",
                  "&:hover": {
                    backgroundColor: "error.dark",
                  },
                }}
                variant="contained"
              >
                {label ? "Yes" : "Delete"}
              </Button>
            </Box>
          </>
        )}
      </Dialog>
      <Button
        data-test={dataTest}
        onClick={handleOpen}
        color="primary"
        sx={{ ml: 2 }}
        disabled={taskId === taskIdFromLocalStorage}
        variant={
          // @ts-ignore
          variant || "contained"
        }
      >
        {label || "Delete"}
      </Button>
    </>
  );
};

export default DeleteConfirmation;
