import { ReactElement } from "react";
import { Box, Grid, Typography } from "@mui/material";

const Header = ({
  children,
  // resources = "tasks",
  // pipelineId,
  title,
}: {
  children?: ReactElement;
  title: string;
  // pipelineId?: string;
  // resources?: string;
}): JSX.Element => (
  <Grid container justifyContent="space-between" spacing={3}>
    <Grid item>
      <Typography color="textPrimary" variant="h5">
        {title}
      </Typography>
      {/* <Breadcrumbs */}
      {/*  aria-label="breadcrumb" */}
      {/*  separator={<ChevronRightIcon fontSize="small" />} */}
      {/*  sx={{ mt: 1 }} */}
      {/* > */}
      {/*  <Link */}
      {/*    color="textPrimary" */}
      {/*    component={RouterLink} */}
      {/*    to="/dashboard" */}
      {/*    variant="subtitle2" */}
      {/*  > */}
      {/*    Dashboard */}
      {/*  </Link> */}
      {/*  <Link */}
      {/*    color="textPrimary" */}
      {/*    component={RouterLink} */}
      {/*    to={`/dashboard/${resources}`} */}
      {/*    variant="subtitle2" */}
      {/*  > */}
      {/*    {camelToSentence(resources)} */}
      {/*  </Link> */}
      {/*  <Link */}
      {/*    color="textPrimary" */}
      {/*    component={RouterLink} */}
      {/*    to={`/dashboard/${resources}/create`} */}
      {/*    variant="subtitle2" */}
      {/*  > */}
      {/*    Create */}
      {/*  </Link> */}
      {/*  {pipelineId && ( */}
      {/*    <Typography color="textSecondary" variant="subtitle2"> */}
      {/*      {pipelineId} */}
      {/*    </Typography> */}
      {/*  )} */}
      {/* </Breadcrumbs> */}
    </Grid>
    <Grid item>
      <Box sx={{ m: -1 }}>{children}</Box>
    </Grid>
  </Grid>
);

export default Header;
