import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { ChonkyIconFA } from "chonky-icon-fontawesome";
import { FileWithPath } from "file-selector";

interface Props {
  handleModalClose: () => void;
  modal: boolean;
  handleDrop: (f: FileWithPath[]) => void;
  files: any[];
  handleUpload: () => void;
}
const UploadModal = ({
  handleModalClose,
  modal,
  handleDrop,
  files,
  handleUpload,
}: Props): JSX.Element => {
  const { getRootProps, getInputProps } = useDropzone({ onDrop: handleDrop });
  return (
    <div {...getRootProps()}>
      <Dialog onClose={handleModalClose} open={modal}>
        <DialogContent
          sx={{
            height: 300,
            width: 350,
          }}
        >
          {modal && files.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <input {...getInputProps()} />

              <ChonkyIconFA
                style={{
                  color: "#9e9e9e",
                  margin: 45,
                  height: 100,
                  width: 100,
                }}
                icon="upload"
              />
              <Typography align="center">
                Drop some files here, or click to select files
              </Typography>
            </Box>
          ) : (
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box p={2}>
                {files.map((file) => (
                  <li key={file.path}>
                    {file.path} - {file.size} bytes
                  </li>
                ))}
              </Box>
              <DialogActions>
                <Button onClick={handleModalClose} color="primary">
                  Cancel
                </Button>
                &nbsp;
                <Button
                  variant="contained"
                  onClick={handleUpload}
                  color="primary"
                >
                  Upload
                </Button>
              </DialogActions>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default UploadModal;
