import { ChangeEvent, Dispatch } from "react";
import { useNavigate } from "react-router-dom";
import { Card, MenuItem, TextField } from "@mui/material";
import { Pipeline } from "../../../types/pipeline";

const SelectPipeline = ({
  setCurrentPipeline,
  pipelines,
  pipelineId,
  streamsOnly = false,
}: {
  setCurrentPipeline: Dispatch<Pipeline>;
  pipelines: Pipeline[];
  pipelineId: string;
  streamsOnly?: boolean;
}): JSX.Element => {
  const nav = useNavigate();
  const handleSelectPipeline = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value: currentId } = event.target;
    const selectedPipeline = pipelines.find((p) => p.id === currentId);
    setCurrentPipeline(selectedPipeline);
    nav(
      `/dashboard/${streamsOnly ? "instances" : "tasks"}/create/${
        selectedPipeline.id
      }`
    );
  };

  return (
    <Card sx={{ p: 3, mb: 2 }}>
      <TextField
        fullWidth
        label="Select Pipeline"
        name="pipeline"
        onChange={handleSelectPipeline}
        value={pipelineId || ""}
        select
        SelectProps={{ displayEmpty: true }}
        variant="outlined"
      >
        {pipelines
          .filter((p) => (streamsOnly ? p.isStream : p.isBatch))
          .map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
      </TextField>
    </Card>
  );
};

export default SelectPipeline;
