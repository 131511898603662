import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
} from "@mui/material";
import * as React from "react";
import { format } from "date-fns";

interface Props {
  columnNames: string[];
  rows: any[];
}

const BasicTable = ({ columnNames, rows }: Props): JSX.Element => (
  <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          {columnNames.map((columnName) => (
            <TableCell>
              <b>{columnName}</b>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <TableRow
            key={row.name}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell
              sx={{
                color: ({ palette }): string =>
                  // eslint-disable-next-line no-nested-ternary
                  row.level === "ERROR"
                    ? palette.error.light
                    : row.level === "WARNING"
                    ? palette.warning.light
                    : palette.primary.main,
              }}
            >
              {row.level}
            </TableCell>
            <TableCell>{row.message}</TableCell>
            <TableCell>{row.module_name}</TableCell>
            <TableCell>
              {format(new Date(row.timestamp), "MMM dd hh:mm a")}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default BasicTable;
