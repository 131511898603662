import { Avatar, Box, Button, Dialog, Typography, alpha } from "@mui/material";
import WarningIcon from "@mui/icons-material/WarningOutlined";

interface DestroyConfirmationModalProps {
  handleDeleteModalClose: () => void;
  deleteModal: boolean;
  handleModalDelete: () => Promise<void>;
  idsToDelete: string[];
}

const DestroyConfirmationModal = ({
  handleDeleteModalClose,
  deleteModal,
  handleModalDelete,
  idsToDelete,
}: DestroyConfirmationModalProps): JSX.Element => (
  <Dialog onClose={handleDeleteModalClose} open={deleteModal}>
    <Box
      sx={{
        display: "flex",
        pb: 2,
        pt: 3,
        px: 3,
      }}
    >
      <Avatar
        sx={{
          backgroundColor: (theme): string =>
            alpha(theme.palette.error.main, 0.08),
          color: "error.main",
          mr: 2,
        }}
      >
        <WarningIcon />
      </Avatar>
      <Box>
        <Typography color="textPrimary" variant="h5">
          Delete the Following files and/or folders?
        </Typography>
        <Box
          sx={{
            my: 2,
            mr: 7,
            maxWidth: 300,
            wordWrap: "break-word",
          }}
        >
          {String(idsToDelete)}
        </Box>
      </Box>
    </Box>
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        px: 3,
        py: 1.5,
      }}
    >
      <Button
        onClick={handleDeleteModalClose}
        color="primary"
        sx={{ mr: 2 }}
        variant="contained"
      >
        Cancel
      </Button>
      <Button
        data-test="delete-confirm"
        onClick={handleModalDelete}
        sx={{
          backgroundColor: "error.main",
          "&:hover": {
            backgroundColor: "error.dark",
          },
        }}
        variant="contained"
      >
        Delete
      </Button>
    </Box>
  </Dialog>
);

export default DestroyConfirmationModal;
