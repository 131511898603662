import { Box, Button } from "@mui/material";
import { toast } from "react-hot-toast";

interface Props {
  message: string;
  resourceId?: string;
  pipelineId: string;
  apiCall: string;
  t: any;
}

const ErrorToast = (p: Props): JSX.Element => (
  <Box
    sx={{
      wordWrap: "break-word",
      width: "100%",
      fontSize: 14,
    }}
  >
    This information could not be displayed. Please contact support with the
    following information:
    <br />
    <Box
      sx={{
        my: 2,
        p: 2,
        background: "rgba(145, 158, 171, 0.24)",
        borderRadius: 1,
      }}
    >
      <Box mb={1}>
        <b>api call:</b>&nbsp;<code style={{ fontSize: 12 }}>{p.apiCall}</code>
      </Box>
      <Box mb={1}>
        <b>message:</b>&nbsp;{p.message}
      </Box>
      <Box mb={1}>
        <b>user id:</b>&nbsp;{localStorage.getItem("userId")}
      </Box>
      <Box mb={1}>
        <b>resource id:</b>&nbsp;{p.resourceId}
      </Box>
      <Box>
        <b>pipeline id:</b>&nbsp;{p.pipelineId}
      </Box>
    </Box>
    <Button onClick={(): void => toast.dismiss(p.t.id)}>Dismiss</Button>
  </Box>
);

export default ErrorToast;
