import {
  CopyObjectCommand,
  DeleteObjectsCommand,
  GetObjectCommand,
  GetObjectCommandOutput,
  ListObjectsV2Command,
  ListObjectsV2CommandOutput,
  PutObjectCommand,
  PutObjectCommandOutput,
  S3Client,
} from "@aws-sdk/client-s3";
import * as path from "path";
import { Upload } from "@aws-sdk/lib-storage";
import { Storage } from "../../../types/storage";

class S3 {
  bucket: string;

  s3: S3Client;

  currentRecursiveKeys: string[];

  constructor(props: Storage) {
    const { bucket, region, accessKeyId, secretAccessKey } = props;
    this.currentRecursiveKeys = [];
    this.bucket = bucket;
    this.s3 = new S3Client({
      region: region || "us-east-1",
      credentials: {
        accessKeyId,
        secretAccessKey,
      },
    });
  }

  listObjects(
    Prefix: string,
    Delimiter?: string
  ): Promise<ListObjectsV2CommandOutput> {
    const cmd = new ListObjectsV2Command({
      Bucket: this.bucket,
      Delimiter: Delimiter === "" ? "" : "/",
      Prefix,
    });

    return this.s3.send(cmd);
  }

  async getPayload(prefix: string): Promise<{
    thisLevelKeys: string[];
    nextLevelKeys: string[];
  }> {
    const { Contents, CommonPrefixes } = await this.listObjects(prefix);
    let thisLevelKeys: string[];
    let nextLevelKeys: string[];
    if (Contents) {
      thisLevelKeys = Contents.map((c) => c.Key);
    }
    if (CommonPrefixes) {
      nextLevelKeys = CommonPrefixes.map((p) => p.Prefix);
    }
    return { thisLevelKeys, nextLevelKeys };
  }

  async getRecursiveKeys(key): Promise<string[]> {
    await this.recurseKeys(key);
    return this.currentRecursiveKeys.flat().filter((k) => k);
  }

  // eslint-disable-next-line consistent-return
  async recurseKeys(key: string): Promise<any> {
    const { thisLevelKeys, nextLevelKeys } = await this.getPayload(key);
    if (!nextLevelKeys) {
      // @ts-ignore
      this.currentRecursiveKeys.push(thisLevelKeys);
      return null;
      // eslint-disable-next-line no-else-return
    } else {
      // @ts-ignore
      this.currentRecursiveKeys.push(thisLevelKeys);
      await Promise.all(
        nextLevelKeys.map(async (nextKey) => this.getRecursiveKeys(nextKey))
      );
    }
  }

  async copy(source: string, destination: string): Promise<void> {
    const cmd = new CopyObjectCommand({
      CopySource: `${this.bucket}/${source}`,
      Key: destination,
      Bucket: this.bucket,
    });
    await this.s3.send(cmd);
  }

  async recursiveCopy(
    sourceKey: string,
    destinationKey: string
  ): Promise<void> {
    if (sourceKey.endsWith("/")) {
      const keys = await this.getRecursiveKeys(sourceKey);
      await Promise.all(
        keys.map(async (key) => {
          const destArray = key.split("/");
          if (sourceKey.split("/").length > destinationKey.split("/").length) {
            const newDestKey = destArray
              .slice(destArray.indexOf(path.basename(destinationKey)))
              .join("/");
            await this.copy(key, newDestKey);
          } else {
            destArray[0] = destinationKey.slice(0, -1);
            const newDestKey = destArray.join("/");
            await this.copy(key, newDestKey);
          }
        })
      );
      this.currentRecursiveKeys = [];
    } else {
      await this.copy(sourceKey, destinationKey);
    }
  }

  async recursiveDelete(keys: string[]): Promise<void> {
    const allKeys = [];
    await Promise.all(
      keys.map(async (key) => {
        if (key.endsWith("/")) {
          const nestedKeys = await this.getRecursiveKeys(key);
          allKeys.push(...nestedKeys);
        } else {
          allKeys.push(key);
        }
      })
    );
    await this.delete(allKeys);
  }

  async delete(keys): Promise<void> {
    const cmd = new DeleteObjectsCommand({
      Bucket: this.bucket,
      Delete: {
        Objects: keys.map((Key) => ({
          Key,
        })),
      },
    });
    await this.s3.send(cmd);
  }

  async putObject(Key: string, Body?: any): Promise<PutObjectCommandOutput> {
    const cmd = new PutObjectCommand({
      Bucket: this.bucket,
      Key,
      Body,
    });
    return this.s3.send(cmd);
  }

  upload(Key: string, Body?: any): Upload {
    return new Upload({
      client: this.s3,
      params: {
        Bucket: this.bucket,
        Key,
        Body,
      },
    });
  }

  async getObject(key: string): Promise<GetObjectCommandOutput> {
    const cmd = new GetObjectCommand({
      Bucket: this.bucket,
      Key: key,
    });

    return this.s3.send(cmd);
  }

  async getBucketSize(): Promise<number> {
    const { Contents } = await this.listObjects("", "");
    const float =
      Contents.map((c) => c.Size).reduce((acc, curr) => acc + curr) / 1000000;
    return Math.round(float);
  }
}

export default S3;
