import { Typography, ListItem, List, Button } from "@mui/material";
import { Dispatch } from "react";
import { CardInfo } from "../../../types/user";

const BillingCardTable = ({
  cardInfo,
  setMode,
}: {
  cardInfo: CardInfo;
  setMode: Dispatch<string>;
}): JSX.Element => (
  <>
    <List
      sx={{
        border: "1px solid rgb(230, 232, 240)",
        borderRadius: 1,
      }}
    >
      <ListItem sx={{ p: 2 }}>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", minWidth: 180 }}
        >
          Last 4:
        </Typography>
        <Typography variant="body2">
          **** {cardInfo.last4 || "Currently processing. Please refresh."}
        </Typography>
      </ListItem>
    </List>
    <Button
      variant="contained"
      sx={{ marginY: 3 }}
      onClick={(): void => {
        setMode("Update");
      }}
    >
      Update
    </Button>
  </>
);

export default BillingCardTable;
