import { ChangeEvent, Dispatch, ReactNode, useEffect, useState } from "react";
import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import ParameterCard from "./ParameterCard";
import { Category } from "../../../types/pipeline";

export interface TabPanelProps {
  children?: ReactNode;
  index: string;
  value: string;
}

export function TabPanel(props: TabPanelProps): JSX.Element {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
const TabbedParameters = (props: {
  editable?: boolean;
  categories?: Category[];
  setCategories?: Dispatch<any>;
}): JSX.Element => {
  const { editable, categories, setCategories } = props;

  const [currentTab, setCurrentTab] = useState<string>("");
  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
  };

  useEffect(() => {
    if (categories[0]) {
      setCurrentTab(categories[0].name);
    }
  }, [categories[0]]);
  return (
    <Grid>
      <Box mt={3} mb={2}>
        <Typography variant="h6">Parameters</Typography>
      </Box>
      <Tabs
        indicatorColor="primary"
        onChange={handleTabsChange}
        scrollButtons="auto"
        textColor="primary"
        value={currentTab}
        variant="scrollable"
      >
        {categories &&
          categories.map((category) => (
            <Tab
              key={category.name}
              label={category.name}
              value={category.name}
            />
          ))}
      </Tabs>
      <Box>
        {categories.map((cat) => (
          <TabPanel key={cat.name} value={cat.name} index={currentTab}>
            {cat.params &&
              cat.params.map((p) => (
                <Box key={p.verbose_name} mb={3}>
                  <ParameterCard
                    categoryName={cat.name}
                    param={p}
                    setCategories={setCategories}
                    editable={editable}
                  />
                </Box>
              ))}
          </TabPanel>
        ))}
      </Box>
    </Grid>
  );
};

export default TabbedParameters;
