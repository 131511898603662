import { Box, Divider, IconButton, TextField } from "@mui/material";
import { ChangeEvent } from "react";
import { useImmer } from "use-immer";
import { PlusIcon, XIcon } from "../../../icons";

interface Props {
  list: any[];
  name: string;
  type: string;
  handleChange: (e: any) => void;
}

const createApplyValues =
  (list, handleChange, name): any =>
  (value, index): void => {
    const updatedList = list.map((cell, i) => {
      if (index === i) {
        return value;
      }
      return cell;
    });
    handleChange({
      target: {
        value: updatedList,
        name,
      },
    });
  };

const validateListOfFloats = (val, index, setErrors, applyValues): void => {
  if (typeof val === "string" && val.endsWith(".")) {
    setErrors((draft) => {
      draft[index] = "Cannot end with period (eg 1.)";
    });
  } else {
    try {
      JSON.parse(val);
      setErrors((draft) => {
        draft[index] = "";
      });
      applyValues(JSON.parse(val), index);
    } catch (err) {
      setErrors((draft) => {
        draft[index] = "Value must be a valid float";
      });
    }
  }
};

const validateListOfLists = (val, index, setErrors, applyValues): void => {
  try {
    const arr = JSON.parse(val.replaceAll("'", '"'));
    if (Array.isArray(arr)) {
      applyValues(arr, index);
      setErrors((draft) => {
        draft[index] = "";
      });
    } else {
      setErrors((draft) => {
        draft[index] = "Must be a valid list";
      });
    }
  } catch (err) {
    setErrors((draft) => {
      draft[index] = "Value must be a valid list.";
    });
  }
};

const ListTypeNative = (p: Props): JSX.Element => {
  const [errors, setErrors] = useImmer<string[]>([
    ...Array(p.list.length).fill(""),
  ]);
  const applyValues = createApplyValues(p.list, p.handleChange, p.name);
  const handleCellChange = (e: ChangeEvent<HTMLInputElement>, index): void => {
    const { value } = e.target;
    applyValues(value, index);
  };
  const handleValidate = (index: number): void => {
    const val = p.list[index];
    // TODO: add other list type validation
    if (val === "") {
      setErrors((draft) => {
        draft[index] = "";
      });
    } else if (p.type === "list[float]") {
      validateListOfFloats(val, index, setErrors, applyValues);
    } else if (p.type === "list[list]") {
      validateListOfLists(val, index, setErrors, applyValues);
    }
  };
  const handleDelete = (index: number): void => {
    p.handleChange({
      target: {
        value: p.list.filter((_, i) => i !== index),
        name: p.name,
      },
    });
  };

  const handleAdd = (): void => {
    p.handleChange({
      target: {
        value: p.list.concat([""]),
        name: p.name,
      },
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      {Array.isArray(p.list) &&
        p.list.map((value, index) => (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              error={Boolean(errors[index])}
              helperText={errors[index]}
              onBlur={(): void => handleValidate(index)}
              value={Array.isArray(value) ? JSON.stringify(value) : value}
              onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                handleCellChange(e, index)
              }
            />
            <IconButton
              sx={{ mx: 1 }}
              onClick={(): void => handleDelete(index)}
            >
              <XIcon />
            </IconButton>
            <Divider sx={{ mr: 2 }} orientation="vertical" flexItem />
          </Box>
        ))}
      <IconButton onClick={handleAdd} sx={{ display: "flex" }}>
        <PlusIcon />
      </IconButton>
    </Box>
  );
};

export default ListTypeNative;
