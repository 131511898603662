import { Helmet } from "react-helmet-async";
import { Box, Container, Grid, Skeleton, Typography } from "@mui/material";

import { useParams } from "react-router";
import useSettings from "../../hooks/useSettings";
import { useGetTaskQuery } from "../../api/api";
import BasicTable from "../../components/BasicTable";

interface Props {
  resourceType: "Task";
}

const Logs = ({ resourceType }: Props): JSX.Element => {
  const { taskId } = useParams();
  const { settings } = useSettings();
  const { data: task, isLoading } = useGetTaskQuery(taskId);
  return (
    <>
      <Helmet>
        <title> {resourceType} Error Logs | NeuroScale</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 8,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                {resourceType} Error Logs
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {isLoading ? (
              <Skeleton
                sx={{
                  width: "100%",
                  height: 600,
                  marginTop: -15,
                }}
              />
            ) : task && task.logs.length > 0 ? (
              <BasicTable
                rows={task.logs}
                columnNames={["Level", "Message", "Module Name", "TimeStamp"]}
              />
            ) : (
              <Box m={3}>No logs for this task</Box>
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Logs;
