export const camelToSentence = (text: string): string => {
  if (text === "id") {
    return "ID";
  }
  const result = text.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const snakeToCamel = (str: string): string =>
  str
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace("-", "").replace("_", "")
    );

export const snakeToSentence = (str: string): string => {
  const res = snakeToCamel(str);
  return camelToSentence(res);
};

export const truncate = (str: string): string => {
  const MAX_LENGTH = 75;
  if (str.length < MAX_LENGTH) {
    return str;
  }
  return `${str.slice(0, MAX_LENGTH)}...`;
};
