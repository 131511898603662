import { useParams } from "react-router-dom";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import {
  useGetInstanceDebugLogQuery,
  useGetInstanceQuery,
} from "../../api/api";
import NotFound from "../NotFound";
import ProgressScreen from "../../components/ProgressScreen";
import TabbedFields from "../../components/dashboard/TabbedFields";

export interface Parameter {
  name?: string;
  value?: unknown;
  category?: string;
  description?: string;
  type?: string;
  verbose_name?: string;
}

const InstanceDetails = (): JSX.Element => {
  const { instanceId } = useParams();
  const {
    data: instanceCategories,
    isLoading,
    error,
  } = useGetInstanceQuery(instanceId);

  const {
    data: debugLog,
    isLoading: isDebugLogLoading,
    error: debugLogError,
  } = useGetInstanceDebugLogQuery(instanceId);
  const [staff, setStaff] = useState(false);

  useEffect(() => {
    setStaff(JSON.parse(localStorage.getItem("is_staff")));
  }, []);

  if (isLoading || isDebugLogLoading) {
    return <ProgressScreen />;
  }
  if (error) {
    console.error(error);
    return <NotFound />;
  }
  return (
    <>
      <Helmet>
        <title> {instanceId} | NeuroScale</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Instance Details
              </Typography>
            </Grid>
            {staff && (
              <Grid item>
                <Button
                  sx={{ m: 1 }}
                  variant="contained"
                  href={`https://console.aws.amazon.com/cloudwatch/home?region=us-east-1#logsV2:log-groups/log-group/cpe-streaming/log-events/${instanceId}`}
                >
                  Cloudwatch Logs
                </Button>
              </Grid>
            )}
          </Grid>
          <Box sx={{ mt: 3 }}>
            <TabbedFields
              debugLogError={debugLogError}
              debugLog={debugLog}
              dataTest="instance-overview"
              fieldCategories={instanceCategories}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default InstanceDetails;
