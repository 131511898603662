import { Box, Card, TextField, Typography } from "@mui/material";
import { ChangeEvent, Dispatch } from "react";
import { Parameter } from "../../../types/pipeline";

const ParameterCard = ({
  categoryName,
  setCategories,
  param,
  editable = false,
}: {
  categoryName?: string;
  param: Parameter;
  setCategories?: Dispatch<unknown>;
  editable?: boolean;
}): JSX.Element => {
  const handleChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>): void => {
    setCategories((draft) => {
      draft
        .find((c) => c.name === categoryName)
        .params.find((p) => p.name === param.name).value = value;
    });
  };
  return (
    <Card>
      <Box m={2}>
        <Box mb={2}>
          <Typography variant="h6">{param.verbose_name}</Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }} mb={3}>
          <b>Value: </b>
          &emsp;
          {editable ? (
            <TextField
              required={Boolean(param.value)}
              type={param.type === "float" ? "number" : "text"}
              data-test={
                param.name === "input_file" ? "task-file-input" : undefined
              }
              inputProps={{ style: { fontFamily: "mono" } }}
              onChange={handleChange}
              value={JSON.stringify(param.value)}
            />
          ) : (
            <code
              style={{
                padding: "6px 3px 1px 3px ",
                borderRadius: 5,
                background: "rgba(145, 158, 171, 0.24)",
              }}
            >
              {JSON.stringify(param.value) || "None"}
            </code>
          )}
        </Box>
        <pre style={{ fontFamily: "sans-serif", whiteSpace: "pre-wrap" }}>
          {param.description}
        </pre>
      </Box>
    </Card>
  );
};

export default ParameterCard;
