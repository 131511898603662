import { Link as RouterLink, useParams } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  Modal,
  Card,
  CardHeader,
  CardContent,
  Link,
  Chip,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { nanoid } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import path from "path";
import {
  BASE_URL,
  useCancelTaskMutation,
  useGetTaskQuery,
} from "../../api/api";
import NotFound from "../NotFound";
import SummaryModal from "../../components/dashboard/pipeline/SummaryModal";
import { formatDate } from "../../utils/formatDate";
import ProgressScreen from "../../components/ProgressScreen";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import { CheckMarkIcon, XIcon } from "../../icons";

export interface Parameter {
  name?: string;
  value?: unknown;
  category?: string;
  description?: string;
  type?: string;
  verbose_name?: string;
}

const TaskDetails = (): JSX.Element => {
  const { taskId } = useParams();
  const { data: task, isLoading, error } = useGetTaskQuery(taskId);
  const [open, setOpen] = useState(false);
  const [staff, setStaff] = useState(false);
  const [statusString, setStatusString] = useState("no errors and no warnings");
  const [hasErrors, setHasErrors] = useState(false);
  const [hasWarnings, setHasWarnings] = useState(false);
  const [cancelTask] = useCancelTaskMutation();
  useEffect(() => {
    if (task && task.logs.length > 0) {
      const currentHasErrors = task.logs.some((t) => t.level === "ERROR");
      setHasErrors(currentHasErrors);
      const currentHasWarnings = task.logs.some((t) => t.level === "WARNING");
      setHasWarnings(currentHasWarnings);
      setStatusString(
        `${currentHasErrors ? "errors" : "no errors"} and ${
          currentHasWarnings ? "warnings" : "no warnings"
        }`
      );
    }
    try {
      setStaff(JSON.parse(localStorage.getItem("is_staff")));
    } catch {
      setStaff(false);
    }
  }, [isLoading]);
  if (isLoading) {
    return <ProgressScreen />;
  }
  if (error) {
    return <NotFound />;
  }
  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleDownload = async (filepath: string): Promise<void> => {
    if (filepath) {
      const token = localStorage.getItem("accessToken");
      const res = await fetch(
        `${BASE_URL}/actions/exchange-token?type=presigned_s3_url&object=${filepath}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const json = await res.json();
      // @ts-ignore
      window.location = json.presigned_s3_url;
    }
  };
  return (
    <>
      <Helmet>
        <title> {task.name} | NeuroScale</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Task Details
              </Typography>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                {task.state !== "cancelled" &&
                  task.state !== "failed" &&
                  task.state !== "succeeded" && (
                    <DeleteConfirmation
                      taskId={taskId}
                      label="Cancel Task"
                      deleteResource={cancelTask}
                      confirmation="Cancel submitted. Please allow some time for process to end."
                      title="Are you sure you want to cancel this task? &nbsp; This cannot be undone."
                      selectedResources={[taskId]}
                    />
                  )}
                &emsp;
                <Button
                  variant="contained"
                  href={`/dashboard/tasks/create/${task.pipeline}?taskId=${task.id}`}
                >
                  Copy Task
                </Button>
                <Button sx={{ m: 1 }} variant="contained" onClick={handleOpen}>
                  More Info
                </Button>
                {staff && (
                  <Button
                    sx={{ m: 1 }}
                    variant="contained"
                    href={`https://console.aws.amazon.com/cloudwatch/home?region=us-east-1#logsV2:log-groups/log-group/batch/log-events/${taskId}`}
                  >
                    Cloudwatch Logs
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Grid container>
              <Box sx={{ width: "100%" }} mb={4}>
                <Box mb={3}>
                  <Card data-test="task-overview">
                    <CardHeader title={task.name} />
                    <CardContent
                      sx={{
                        maxHeight: 300,
                        overflow: "auto",
                        mb: 2,
                      }}
                    >
                      <pre
                        style={{
                          fontFamily: "sans-serif",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {task.description || <em>No description provided.</em>}
                      </pre>
                    </CardContent>
                  </Card>
                </Box>
                <Box>
                  <Card sx={{ p: 3 }}>
                    <b>Status:</b>&emsp;
                    <Chip
                      variant="outlined"
                      label={`${task.state.toUpperCase()} with ${statusString}`}
                      color={
                        // eslint-disable-next-line no-nested-ternary
                        hasErrors
                          ? "error"
                          : hasWarnings
                          ? "warning"
                          : "success"
                      }
                    />
                    &emsp;
                    {(hasErrors || hasWarnings) && (
                      <Button
                        variant="contained"
                        component={RouterLink}
                        to="logs"
                      >
                        Error Logs
                      </Button>
                    )}
                    <br />
                    <br />
                    <b>Task Inputs:</b>
                    <br />
                    {task.inputs.length &&
                      task.inputs.map((r) => (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {/* eslint-disable-next-line no-nested-ternary */}
                          {r.success === undefined ? null : r.success ? (
                            <CheckMarkIcon />
                          ) : (
                            <XIcon color="error" />
                          )}
                          &emsp;
                          <Link
                            href={`/dashboard/storage?io=${path.dirname(
                              r.file_path
                            )}`}
                          >
                            {r.file_path}
                          </Link>
                          <br />
                        </Box>
                      ))}
                    <br />
                    <br />
                    <b>Task Outputs:</b>
                    <br />
                    {task.outputs.length &&
                      task.outputs.map((r) => (
                        <>
                          &emsp;
                          <Link
                            onClick={(): Promise<void> =>
                              handleDownload(r.file_path)
                            }
                          >
                            {r.file_path}
                          </Link>
                          <br />
                        </>
                      ))}
                  </Card>
                </Box>
                <Box mt={3} mb={2}>
                  <Typography variant="h6">Parameters</Typography>
                </Box>
                {Object.keys(task.parameters)
                  .map((key) => ({ name: key, value: task.parameters[key] }))
                  .map((param) => (
                    <Box key={nanoid()} mb={3}>
                      <Card>
                        <Box m={2}>
                          <Box mb={2}>
                            <Typography variant="h6">{param.name}</Typography>
                          </Box>
                          <Box
                            sx={{ display: "flex", alignItems: "center" }}
                            mb={3}
                          >
                            <b>Value: </b>
                            &emsp;
                            <code
                              style={{
                                padding: "6px 3px 1px 3px ",
                                borderRadius: 5,
                                background: "rgba(145, 158, 171, 0.24)",
                              }}
                            >
                              {JSON.stringify(param.value) || "None"}
                            </code>
                          </Box>
                        </Box>
                      </Card>
                    </Box>
                  ))}
              </Box>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Modal open={open} onClose={handleClose}>
        <Box>
          <SummaryModal
            createdAt={formatDate(task.created_at)}
            updatedAt={formatDate(task.updated_at)}
            handleClose={handleClose}
            id={task.id}
            state={task.state}
            exitCode={task.exit_code || false}
            exitReason={task.exit_reason || false}
            stateReason={task.state_reason || "None"}
            pipelineId={task.pipeline}
          />
        </Box>
      </Modal>
    </>
  );
};

export default TaskDetails;
