import { Link as RouterLink } from "react-router-dom";
import {
  experimentalStyled,
  AppBar,
  Box,
  IconButton,
  Toolbar,
  AppBarProps,
} from "@mui/material";
import { MenuIcon } from "../../icons";
import AccountPopover from "./AccountPopover";
import Logo from "../Logo";
import NotificationsPopover from "./NotificationsPopover";

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
}

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === "light" && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: "none",
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: "none",
  }),
  zIndex: theme.zIndex.drawer + 100,
}));

const DashboardNavbar = (props: DashboardNavbarProps): JSX.Element => {
  const { onSidebarMobileOpen, ...other } = props;

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 64 }}>
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              lg: "none",
            },
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <RouterLink to="/">
          <Logo
            sx={{
              display: {
                lg: "inline",
                xs: "none",
              },
              pt: 0.7,
              height: 40,
              width: 200,
              color: "white",
            }}
          />
        </RouterLink>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />
        <Box sx={{ mr: 1 }}>
          <NotificationsPopover />
        </Box>
        <Box sx={{ mr: 6.5 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

export default DashboardNavbar;
