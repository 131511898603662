import { Box, IconButton, Paper, Typography } from "@mui/material";
import { XIcon } from "../icons";

const ModalCard = ({
  children,
  handleClose,
}: {
  children: unknown;
  handleClose: () => void;
}): JSX.Element => (
  <Box>
    <Paper
      elevation={12}
      sx={{
        maxHeight: "90vh",
        maxWidth: "90vw",
        overflow: "auto",
        p: 2,
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          px: 2,
          py: 1,
          mb: 1,
        }}
      >
        <Typography variant="h5" color="textPrimary">
          Additional Information
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton onClick={handleClose}>
          <XIcon fontSize="small" />
        </IconButton>
      </Box>
      {children}
    </Paper>
  </Box>
);

export default ModalCard;
