import path from "path";
import JSZip from "jszip";
import S3 from "./S3";
import { FileArray, FileData } from "./chonky";

export const formatPrefix = (prefix: string): string =>
  prefix === "/" ? "" : prefix;

export const getCurrentFiles = async (
  s3: S3,
  currentFolderId: string
): Promise<FileArray> => {
  const listOutput = await s3.listObjects(formatPrefix(currentFolderId));
  const chonkyFiles: FileArray = [];
  if (listOutput) {
    if (listOutput.Contents) {
      chonkyFiles.push(
        ...listOutput.Contents.filter(({ Key }) => !Key.endsWith("/")).map(
          (object) => ({
            id: object.Key,
            name: path.basename(object.Key),
            modDate: object.LastModified,
            size: object.Size,
          })
        )
      );
    }
    if (listOutput.CommonPrefixes) {
      chonkyFiles.push(
        ...listOutput.CommonPrefixes.map((p) =>
          p.Prefix === "/"
            ? null
            : {
                id: p.Prefix,
                name: `${path.basename(p.Prefix)}/`,
                isDir: true,
              }
        )
      );
    }
  }
  return chonkyFiles.filter((f) => f);
};

export const getFolderChain = (currentFolderId, bucket: string): FileArray => {
  let fc: FileArray;
  if (currentFolderId === "/") {
    fc = [];
  } else {
    let currentPrefix = "";
    fc = currentFolderId
      .replace(/\/*$/, "")
      .split("/")
      .map((prefixPart): FileData => {
        currentPrefix = currentPrefix
          ? path.join(currentPrefix, prefixPart)
          : prefixPart;
        return {
          id: `${currentPrefix}/`,
          name: prefixPart,
          isDir: true,
        };
      });
  }
  return [{ id: "/", name: bucket, isDir: true }, ...fc.filter((f) => f.name)];
};

export const downloadFile = (blob: Blob, fileName = ""): void => {
  // Create an invisible A element
  const a = document.createElement("a");
  a.style.display = "none";
  document.body.appendChild(a);

  // Set the HREF to a Blob representation of the data to be downloaded
  a.href = window.URL.createObjectURL(blob);

  // Use download attribute to set set desired file name
  a.setAttribute("download", fileName);

  // Trigger the download by simulating click
  a.click();

  // Cleanup
  window.URL.revokeObjectURL(a.href);
  document.body.removeChild(a);
};

export const compressAndDownload = async (
  parent: string,
  s3: S3
): Promise<void> => {
  const childFiles = await s3.getRecursiveKeys(parent);

  const zip = new JSZip();
  await Promise.all(
    childFiles.map(async (filename) => {
      const resp = await s3.getObject(filename);
      const readableStream = resp.Body as ReadableStream;
      const response = new Response(readableStream);
      const blob = await response.blob();
      zip.file(filename, blob);
    })
  );

  zip
    .generateAsync({ type: "blob" })
    .then((c) => downloadFile(c, `${parent.slice(0, -1)}.zip`));
};
export const compressMultiAndDownload = async (
  files: string[],
  s3: S3
): Promise<void> => {
  const zip = new JSZip();
  await Promise.all(
    files.map(async (filename) => {
      const resp = await s3.getObject(filename);
      const readableStream = resp.Body as ReadableStream;
      const response = new Response(readableStream);
      const blob = await response.blob();
      zip.file(filename, blob);
    })
  );

  zip
    .generateAsync({ type: "blob" })
    .then((c) => downloadFile(c, `ns_files.zip`));
};
