import { createSvgIcon } from "@mui/material";

const ChartSquareBar = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="15 15 75 75"
    fill="currentColor"
  >
    <path d="M77.281 16.993H22.197c-3.269 0-5.921 2.695-5.921 5.97v55.183a5.92 5.92 0 005.926 5.924h55.084a5.92 5.92 0 005.921-5.924V22.914a5.92 5.92 0 00-5.926-5.921zm-.754 59.183c0 .334-.27.655-.605.655H23.565a.61.61 0 01-.61-.609V24.884c0-.331.274-.654.61-.654h52.352c.34 0 .61.277.61.611v51.335z" />
    <path d="M27.769 63.611h9.378v8.591h-9.378zM39.292 52.886h9.378v19.316h-9.378zM50.814 33.57h9.378v38.632h-9.378zM62.337 45.709h9.378v26.493h-9.378z" />
  </svg>,
  "ChartSquareBar"
);

export default ChartSquareBar;
