import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Theme,
} from "@mui/material";
import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import type { Domain } from "../../../types/pipeline";

interface Props {
  domain: Domain;
  name: string;
  handleChange: (e: any, type: string) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(
  name: string,
  personName: readonly string[],
  theme: Theme
): any {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const listToStr = (list): string => JSON.stringify(list).replace('"', "'");
const MultiSelectType = ({
  domain,
  name,
  handleChange,
}: Props): JSX.Element => {
  const theme = useTheme();
  const [val, setVal] = useState<string[]>([]);
  const handleLocalChange = ({ target: { value } }: any): void => {
    setVal(typeof value === "string" ? value.split(",") : value);
    const event = { target: { name, value: listToStr(val) } };
    handleChange(event, "object");
  };
  return (
    <FormControl sx={{ m: 1, width: 300 }}>
      <InputLabel>Mutli Select</InputLabel>
      <Select
        multiple
        value={val}
        onChange={handleLocalChange}
        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        renderValue={(selected): JSX.Element => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {domain.values.map((n) => (
          <MenuItem key={n} value={n} style={getStyles(n, val, theme)}>
            {n}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiSelectType;
