import { Box, Card, IconButton } from "@mui/material";
import type { ChangeEvent, MouseEvent } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import Scrollbar from "../../Scrollbar";
import type { Resource } from "../../../types/resources";
import SortAndFilter, { Sort, FilterBy, FilterByOption } from "./SortAndFilter";
import { applySort, getSortOptions } from "./applySort";
import { applyFilters, applyFilterBy } from "./applyFilter";
import ResourceTable from "./ResourceTable";
import BulkEdit from "./BulkEdit";
import ResourceTabs, { TabOption } from "./ResourceTabs";
import { RefreshIcon } from "../../../icons";
import { Task } from "../../../types/tasks";

interface ResourceListProps {
  type?: string;
  headers: string[];
  resources: Resource[];
  total_count?: number;
  tabs?: TabOption[];
  bulkEdit?: boolean;
  deleteResource?: (id: string) => void;
  dataTest?: string;
  resourceType: "authorizations" | "tasks" | "pipeline" | "instances";
  handlePageChange?: (
    e: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  page?: number;
}

const ResourceList = (props: ResourceListProps): JSX.Element => {
  // props
  const {
    type,
    resources,
    tabs,
    dataTest,
    bulkEdit,
    deleteResource,
    headers,
    resourceType,
    total_count: totalCount,
    handlePageChange,
    page,
  } = props;
  const filterByOptions: FilterByOption[] = [
    { value: "all", label: "All" },
    { value: "running", label: "In Progress" },
    { value: "succeeded", label: "Completed" },
  ];
  // hooks
  const { pathname } = useLocation();
  const sortOptions = getSortOptions(pathname.includes("pipelines"));
  const [sort, setSort] = useState<Sort>(sortOptions[0].value);
  const [filterBy, setFilterBy] = useState<FilterBy>(filterByOptions[0].value);
  const [query, setQuery] = useState<string>("");
  const [filters, setFilters] = useState<unknown>({
    isBatch: null,
    isStream: null,
  });
  const [selectedResources, setSelectedResources] = useState<string[]>([]);
  const [currentTab, setCurrentTab] = useState<string>("all");

  // handlers
  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setQuery(event.target.value);
  };
  const handleSortChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSort(event.target.value as Sort);
  };
  const handleFilterByChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setFilterBy(event.target.value as FilterBy);
  };
  const handleSelectAllResources = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setSelectedResources(
      event.target.checked ? resources.map((resource) => resource.id) : []
    );
  };
  const handleSelectOneResource = (
    event: ChangeEvent<HTMLInputElement>,
    resourceId: string
  ): void => {
    if (!selectedResources.includes(resourceId)) {
      setSelectedResources((prevSelected) => [...prevSelected, resourceId]);
    } else {
      setSelectedResources((prevSelected) =>
        prevSelected.filter((id) => id !== resourceId)
      );
    }
  };
  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    const updatedFilters = {
      // @ts-ignore
      ...filters,
      isBatch: null,
      isStream: null,
    };
    if (value !== "all") {
      updatedFilters[value] = true;
    }
    setFilters(updatedFilters);
    setSelectedResources([]);
    setCurrentTab(value);
  };

  // computed data
  const filteredResources = applyFilters(resources, query, filters);
  const filterByResources = applyFilterBy(
    filteredResources as Task[],
    filterBy
  );
  const sortedResources = applySort(filterByResources, sort);
  const enableBulkActions = selectedResources.length > 0;
  const selectedSomeResources =
    selectedResources.length > 0 && selectedResources.length < resources.length;
  const selectedAllResources = selectedResources.length === resources.length;

  const name = pathname.split("/")[2];
  return (
    <>
      {tabs && (
        <Box mb={2}>
          <ResourceTabs
            tabs={tabs}
            currentTab={currentTab}
            handleTabsChange={handleTabsChange}
          />
        </Box>
      )}
      <Card data-test={dataTest}>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexWrap: "wrap",
            m: -1,
            p: 2,
          }}
        >
          <SortAndFilter
            name={name}
            sortOptions={sortOptions}
            sort={sort}
            handleSortChange={handleSortChange}
            handleQueryChange={handleQueryChange}
            query={query}
            filterByOptions={filterByOptions}
            filterBy={filterBy}
            handleFilterByChange={handleFilterByChange}
          />
          {bulkEdit && enableBulkActions && (
            <BulkEdit
              resourceType={resourceType}
              deleteResource={deleteResource}
              selectedResources={selectedResources}
              selectedAllResources={selectedAllResources}
              selectedSomeResources={selectedSomeResources}
              handleSelectAllResources={handleSelectAllResources}
            />
          )}
          <IconButton
            onClick={(): void => {
              window.location.reload();
            }}
          >
            <RefreshIcon />
          </IconButton>
        </Box>
        <Scrollbar>
          <Box sx={{ minWidth: 700 }}>
            <ResourceTable
              type={type}
              bulkEdit={bulkEdit}
              name={resourceType}
              headers={headers}
              resources={sortedResources.sort((a, b) => {
                // @ts-ignore
                if (a.name && b.name && resourceType === "pipeline") {
                  // @ts-ignore
                  return a.name.localeCompare(b.name);
                }
                return sortedResources;
              })}
              page={page}
              handlePageChange={handlePageChange}
              totalCount={totalCount}
              selectedResources={selectedResources}
              handleSelectOneResource={handleSelectOneResource}
              handleSelectAllResources={handleSelectAllResources}
              selectedAllResources={selectedAllResources}
              selectedSomeResources={selectedSomeResources}
            />
          </Box>
        </Scrollbar>
      </Card>
    </>
  );
};

export default ResourceList;
