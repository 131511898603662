import { ReactElement } from "react";
import { Box, Container } from "@mui/material";
import { Helmet } from "react-helmet-async";

// @ts-ignore
const DashPageLayout = ({
  children,
  resource = "Task",
}: {
  children: ReactElement;
  resource?: string;
}): JSX.Element => (
  <>
    <Helmet>
      <title> Create {resource} | NeuroScale</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: "background.default",
        minHeight: "100%",
        py: 8,
      }}
    >
      <Container maxWidth="xl">{children}</Container>
    </Box>
  </>
);
export default DashPageLayout;
