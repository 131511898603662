import { ChangeEvent } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";

interface FormModalProps {
  handleModalClose: () => void;
  modal: boolean;
  handleModalValue: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: () => void;
  modalValue: string;
  errorMsg: string;
  isCreate?: boolean;
}

const FormModal = ({
  handleModalClose,
  modal,
  handleModalValue,
  modalValue,
  handleSubmit,
  errorMsg,
  isCreate,
}: FormModalProps): JSX.Element => (
  <Dialog onClose={handleModalClose} open={modal}>
    <DialogTitle>Please provide the new name:</DialogTitle>
    <DialogContent>
      <TextField
        autoFocus
        margin="dense"
        fullWidth
        value={modalValue}
        onChange={handleModalValue}
        error={Boolean(errorMsg)}
        helperText={errorMsg}
      />
      <DialogActions sx={{ width: "35rem" }}>
        <Button onClick={handleModalClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
      <Typography>
        {isCreate
          ? 'New folder names cannot end in "/"'
          : !"Please dont forget the file extension. eg: test.m example.mat"}
      </Typography>
    </DialogContent>
  </Dialog>
);

export default FormModal;
