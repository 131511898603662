import { nanoid } from "@reduxjs/toolkit";
import { Box, IconButton, TextField } from "@mui/material";
import { ChangeEvent } from "react";
import { useImmer } from "use-immer";
import { PlusIcon, XIcon } from "../../../icons";

interface Props {
  dict: any;
  name: string;
  type: string;
  handleChange: (e: any) => void;
}

const objToArr = (o): any =>
  Object.keys(o).map((k) => ({
    [k]: typeof o[k] === "object" ? JSON.stringify(o[k]) : o[k],
    id: nanoid(),
  }));

const arrToObj = (arr): any =>
  arr.reduce((a, c) => {
    const key = Object.keys(c)[0];
    return { ...a, [key]: c[key] };
  }, {});
const DictTypeNative = (p: Props): JSX.Element => {
  const dictAsArr = objToArr(p.dict);
  const [errors, setErrors] = useImmer<string[]>([
    ...Array(dictAsArr.length).fill(""),
  ]);
  const handleAdd = (): void => {
    const concated = dictAsArr.concat([{ "": "", id: nanoid() }]);
    p.handleChange({ target: { value: arrToObj(concated), name: p.name } });
  };
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: string
  ): void => {
    const { name, value } = e.target;
    const updatedVals = dictAsArr.map((obj) => {
      const key = Object.keys(obj)[0];
      if (name === "key" && obj.id === id) {
        return { [value]: obj[key], id };
      }
      if (name === "val" && obj.id === id) {
        return { [key]: value, id };
      }
      return obj;
    });
    p.handleChange({ target: { value: arrToObj(updatedVals), name: p.name } });
  };

  const handleDelete = (id: string): void => {
    const filtered = dictAsArr.filter((o) => o.id !== id);
    p.handleChange({ target: { value: arrToObj(filtered), name: p.name } });
  };
  const handleValidate = (e, index, id): void => {
    const { value, name } = e.target;
    if (value === "") {
      setErrors((draft) => {
        draft[index] = "";
      });
    } else if (p.type === "dict[str,float]") {
      if (typeof value === "string" && value.endsWith(".")) {
        setErrors((draft) => {
          draft[index] = "Cannot end with period (eg 1.)";
        });
      } else {
        try {
          JSON.parse(value);
          const updatedVals = dictAsArr.map((obj) => {
            const key = Object.keys(obj)[0];
            if (name === "val" && obj.id === id) {
              return { [key]: JSON.parse(value), id };
            }
            return obj;
          });
          p.handleChange({
            target: { value: arrToObj(updatedVals), name: p.name },
          });

          setErrors((draft) => {
            draft[index] = "";
          });
        } catch (err) {
          setErrors((draft) => {
            draft[index] = "Value must be a valid float";
          });
        }
      }
    }
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {dictAsArr.map((obj, i) => {
          const key = Object.keys(obj)[0];
          return (
            <Box
              sx={{
                display: "flex",
                m: 1,
                alignItems: "center",
              }}
            >
              <TextField
                name="key"
                value={key}
                onChange={(e): void => handleChange(e, obj.id)}
              />
              &nbsp;<p>:</p>&nbsp;
              <TextField
                name="val"
                value={obj[key]}
                onChange={(e): void => handleChange(e, obj.id)}
                fullWidth
                error={Boolean(errors[i])}
                helperText={errors[i]}
                onBlur={(e: any): void => handleValidate(e, i, obj.id)}
              />
              <IconButton onClick={(): any => handleDelete(obj.id)}>
                <XIcon />
              </IconButton>
            </Box>
          );
        })}
        <Box>
          <IconButton onClick={handleAdd}>
            <PlusIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};
export default DictTypeNative;
