import { createSvgIcon } from "@mui/material";

const Instance = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 -5 180 180"
  >
    <path
      fillRule="evenodd"
      d="M82 164A82 82 0 1082 0a82 82 0 000 164zm-20-32c4 0 6-3 6-6V38c0-3-2-6-6-6l-4 2-23 22c-2 2-3 6 0 8 2 3 6 3 8 1l13-12v73c0 3 3 6 6 6zm40-100c-3 0-6 3-6 6v88c0 3 3 6 6 6l4-2 23-22c3-2 3-6 1-8-3-3-7-3-9-1l-13 12V38c0-3-3-6-6-6z"
      clipRule="evenodd"
    />
  </svg>,
  "Instance"
);

export default Instance;
